import { createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { getTime, startOfDay } from 'date-fns';

import type { RootState } from '../../configuration/setup/store';
import type { TranslatedMessage } from '../../services/chatAdministrationApi';
import { useAppSelector } from '../../configuration/setup/hooks';
import { resolveMessageStatus } from './messageStatus/messageStatus';

export type Channel = {
    channelId: string;
    unreadMessageCount: number;
    lastMessage?: Message;
    messages?: { [messageId: string]: Message };
};

export type Message = {
    channelId: string;
    id: number;
    type: 'base' | 'user' | 'file' | 'admin';
    text: string;
    userId: string;
    createdAt: number;
    translations?: Record<string, string>;
};

export type User = {
    userId: string;
    nickname: string;
};

export type Reaction = {
    value: string;
    user: User;
};

export type ChatState = {
    userId: string | undefined;
    initialized: boolean;
    isChatVisible: boolean;
    channels: { [channelId: string]: Channel };
    deliveryReceipts: { [channelMessageId: string]: string[] };
    readReceipts: { [channelMessageId: string]: string[] };
    reactions: { [channelMessageId: string]: Reaction[] };
    chatError?: ChatErrorTextWithContext;
    activeChannelId?: string;
    hideChatNavigationBar?: boolean;
};

const initialState: ChatState = {
    userId: undefined,
    initialized: false,
    isChatVisible: false,
    channels: {},
    deliveryReceipts: {},
    readReceipts: {},
    reactions: {},
    chatError: undefined,
    activeChannelId: undefined,
    hideChatNavigationBar: false,
};

export const ChatErrorTypes = {
    NOT_MEMBER_OF_CHAT_GROUP: 'NOT_MEMBER_OF_CHAT_GROUP',
    CHAT_NOT_FOUND: 'CHAT_NOT_FOUND',
    CONNECTION_NOT_ESTABLISHED: 'CONNECTION_NOT_ESTABLISHED',
} as const;

export type ChatErrorType = (typeof ChatErrorTypes)[keyof typeof ChatErrorTypes];

export type BackendError = {
    title: string;
    status: number;
    detail?: string;
};

// from https://sendbird.com/docs/chat/platform-api/v3/error-codes
export type SendbirdError = {
    code: number;
    error: boolean;
    message: string;
};

export type ChatErrorTextWithContext = {
    errorType: ChatErrorType;
    errorText?: string;
};

export type Receipt = {
    messageId: number;
    userIds: string[];
};

export type ReceiptsUpdate = {
    channelId: string;
    receipts: Receipt[];
};

export type ReactionUpdate = {
    channelId: string;
    messageId: number;
    operation: 'add' | 'delete';
    reaction: Reaction;
};

const upsertChannel = (state: ChatState, channel: Channel) => {
    const channelId = channel.channelId;
    const stateChannel = state.channels[channelId];
    if (!stateChannel) {
        state.channels[channel.channelId] = channel;
    } else {
        stateChannel.unreadMessageCount = channel.unreadMessageCount;
        stateChannel.lastMessage = channel.lastMessage;
    }
};

const deleteChannel = (state: ChatState, channelId: string) => {
    delete state.channels[channelId];
};

const insertMessage = (state: ChatState, message: Message) => {
    const channelId = message.channelId;
    const channel = state.channels[channelId];
    if (message.id === 0) {
        return;
    }
    if (!channel) {
        state.channels[message.channelId] = {
            channelId: message.channelId,
            unreadMessageCount: 0,
            messages: {
                [message.id]: message,
            },
        };
    } else {
        channel.messages = {
            ...channel.messages,
            [message.id]: message,
        };
    }
};

const updateMessage = (state: ChatState, message: TranslatedMessage) => {
    const channelId = message.chatId;
    const channel = state.channels[channelId];

    if (!channel || message.messageId === 0) {
        return;
    }

    channel.messages = {
        ...(channel.messages || {}),
        [message.messageId]: {
            ...(channel.messages?.[message.messageId] || {}),
            translations: message.translations,
        },
    };
};

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        channelSelected: (state, action: PayloadAction<string | undefined>) => {
            state.activeChannelId = action.payload;
        },
        chatInitialized: state => {
            state.initialized = true;
        },
        chatVisibilityChanged: (state, action: PayloadAction<boolean>) => {
            state.isChatVisible = action.payload;
        },
        chatErrorResetted: state => {
            state.chatError = undefined;
        },
        chatError: (state, action: PayloadAction<ChatErrorTextWithContext>) => {
            state.chatError = action.payload;
        },
        groupChannelsAdded: (state, action: PayloadAction<Channel[]>) => {
            action.payload.forEach((channel: Channel) => {
                upsertChannel(state, channel);
            });
        },
        groupChannelsUpdated: (state, action: PayloadAction<Channel[]>) => {
            action.payload.forEach((channel: Channel) => {
                upsertChannel(state, channel);
            });
        },
        groupChannelsDeleted: (state, action: PayloadAction<string[]>) => {
            action.payload.forEach((channelId: string) => {
                deleteChannel(state, channelId);
            });
        },
        messagesAdded: (state, action: PayloadAction<Message[]>) => {
            const messages = action.payload;
            messages.forEach(message => insertMessage(state, message));
        },
        messageTranslated: (state, action: PayloadAction<TranslatedMessage>) => {
            const message = action.payload;
            updateMessage(state, message);
        },
        userIdFetched: (state, action: PayloadAction<string>) => {
            state.userId = action.payload;
        },
        chatNavigationBarChanged: (state, action: PayloadAction<boolean>) => {
            state.hideChatNavigationBar = action.payload;
        },
        deliveryReceiptsUpdateReceived: (state, action: PayloadAction<ReceiptsUpdate>) => {
            const channel = action.payload.channelId;
            action.payload.receipts.forEach(deliveryStatusUpdate => {
                const receiptKey = `${channel}/${deliveryStatusUpdate.messageId}`;
                if (state.deliveryReceipts[receiptKey]) {
                    state.deliveryReceipts[receiptKey] = Array.from(
                        new Set([...state.deliveryReceipts[receiptKey], ...deliveryStatusUpdate.userIds])
                    );
                } else {
                    state.deliveryReceipts[receiptKey] = deliveryStatusUpdate.userIds;
                }
            });
        },
        readReceiptsUpdateReceived: (state, action: PayloadAction<ReceiptsUpdate>) => {
            const channel = action.payload.channelId;
            action.payload.receipts.forEach(deliveryStatusUpdate => {
                const receiptKey = `${channel}/${deliveryStatusUpdate.messageId}`;
                if (state.readReceipts[receiptKey]) {
                    state.readReceipts[receiptKey] = Array.from(
                        new Set([...state.readReceipts[receiptKey], ...deliveryStatusUpdate.userIds])
                    );
                } else {
                    state.readReceipts[receiptKey] = deliveryStatusUpdate.userIds;
                }
            });
        },
        updateReactions: (state, action: PayloadAction<ReactionUpdate>) => {
            const reactionKey = `${action.payload.channelId}/${action.payload.messageId}`;
            if (action.payload.operation === 'add') {
                if (state.reactions[reactionKey]) {
                    const set = new Set([...state.reactions[reactionKey], action.payload.reaction]);
                    state.reactions[reactionKey] = Array.from(set);
                } else {
                    state.reactions[reactionKey] = [action.payload.reaction];
                }
            } else {
                if (!state.reactions[reactionKey]) {
                    return;
                }

                state.reactions[reactionKey] = state.reactions[reactionKey]?.filter(
                    reaction => reaction.value !== action.payload.reaction.value
                );
            }
        },
    },
});

export const {
    groupChannelsAdded,
    groupChannelsUpdated,
    groupChannelsDeleted,
    messagesAdded,
    chatInitialized,
    chatErrorResetted,
    chatError,
    channelSelected,
    chatVisibilityChanged,
    userIdFetched,
    chatNavigationBarChanged,
    readReceiptsUpdateReceived,
    deliveryReceiptsUpdateReceived,
    updateReactions,
    messageTranslated,
} = chatSlice.actions;

const getChannels = (state: RootState) => state.chat.channels;

const getGroupChannels = createSelector([getChannels], channels => {
    const channelList = Object.values(channels);
    return channelList.sort((a, b) => (b.lastMessage?.createdAt || 0) - (a.lastMessage?.createdAt || 0));
});

export const getActiveChannel = (state: RootState) => {
    const activeChannelId = getActiveChannelId(state);
    if (activeChannelId) {
        return state.chat.channels[activeChannelId];
    }
    return null;
};

export const getSortedChannelMessages = createSelector([getActiveChannel], channel => {
    if (channel?.messages) {
        const messages = Object.values(channel.messages);
        return messages.sort((a, b) => a.createdAt - b.createdAt);
    }
});

export const getGroupedChannelMessages = createSelector([getSortedChannelMessages], messages => {
    if (messages) {
        const groupedMessages = messages.reduce((agg: { [key: string]: Message[] }, message) => {
            const timestamp = getTime(startOfDay(message.createdAt));
            agg[timestamp] = [...(agg[timestamp] ?? []), message];
            return agg;
        }, {});
        return Object.keys(groupedMessages).map(timestamp => ({
            timestamp: Number.parseInt(timestamp),
            messages: groupedMessages[timestamp],
        }));
    }
});

const isInitialized = (state: RootState) => state.chat.initialized;

const isChatInView = (state: RootState) => state.chat.isChatVisible;

const getChatLoadingError = (state: RootState) => state.chat.chatError;

const getUserId = (state: RootState) => state.chat.userId;

const getActiveChannelId = (state: RootState) => state.chat.activeChannelId;

const getTotalUnreadMessageCount = createSelector([getChannels], channels => {
    const channelList = Object.values(channels);
    return channelList.reduce((total, channel) => total + channel.unreadMessageCount, 0);
});

const getDeliveryStatusForMessage = createSelector(
    [
        (state: RootState, channelId: string, messageId: number) =>
            state.chat.deliveryReceipts[`${channelId}/${messageId}`],
    ],
    deliveryStatuses => deliveryStatuses ?? []
);

const getReadReceiptsForMessage = createSelector(
    [(state: RootState, channelId: string, messageId: number) => state.chat.readReceipts[`${channelId}/${messageId}`]],
    readReceipts => readReceipts ?? []
);

const getMessageStatus = createSelector([getDeliveryStatusForMessage, getReadReceiptsForMessage], resolveMessageStatus);

const getReaction = (state: RootState, channelId: string, messageId: number) =>
    state.chat.reactions[`${channelId}/${messageId}`];

export const useGroupChannels = () => useAppSelector(getGroupChannels);

export const useIsInitialized = () => useAppSelector(isInitialized);

export const useIsChatInView = () => useAppSelector(isChatInView);

export const useChatError = () => useAppSelector(getChatLoadingError);

export const useUserId = () => useAppSelector(getUserId);

export const useActiveChatId = () => useAppSelector(getActiveChannelId);

export const useTotalUnreadMessageCount = () => useAppSelector(getTotalUnreadMessageCount);

export const useSortedChannelMessages = () => useAppSelector(getSortedChannelMessages);

export const useGroupedChannelMessages = () => useAppSelector(getGroupedChannelMessages);

export const useIsChatNavigationEnabled = () => useAppSelector((state: RootState) => !state.chat.hideChatNavigationBar);

export const useMessageStatus = (channelId: string, messageId: number) =>
    useAppSelector(state => getMessageStatus(state, channelId, messageId));

export const useReaction = (channelId: string, messageId: number) =>
    useAppSelector(state => getReaction(state, channelId, messageId));

export default chatSlice.reducer;
