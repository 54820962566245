import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import AppNavigationBar from '@rio-cloud/rio-uikit/AppNavigationBar';
import FeedbackRating from '@rio-cloud/rio-uikit/FeedbackRating';
import SimpleTooltip from '@rio-cloud/rio-uikit/SimpleTooltip';
import Button from '@rio-cloud/rio-uikit/Button';
import { motion } from '@rio-cloud/rio-uikit/framer-motion';

import { useAppContext } from '../layout/AppContext';
import { useFeedback } from '../hooks/useFeedback';

export const FeedbackPage = () => {
    const { navigateToDefault } = useAppContext();

    const intl = useIntl();

    const [rating, setRating] = useState<number>();

    const { isSubmitted, handleSubmit } = useFeedback(rating);

    const handleNavigateBack = () => navigateToDefault('from feedback page');
    const handleNavigateBackAfterSubmit = () => navigateToDefault('from feedback page after submit');

    const handleRatingChanged = (value: number | undefined) => setRating(value);

    return (
        <div className='display-flex flex-column height-100vh'>
            <AppNavigationBar
                title={<FormattedMessage id='intl-msg:chat.feedback.title' />}
                className='flex-0 border border-bottom-only bg-white z-index-max height-50'
                onNavigateBack={handleNavigateBack}
            />
            {!isSubmitted && (
                <form className='flex-1-1 padding-20 overflow-y-auto' onSubmit={handleSubmit}>
                    <p className='margin-top-5 margin-bottom-25 text-color-darker'>
                        <FormattedMessage id='intl-msg:chat.feedback.description' />
                    </p>
                    <FeedbackRating
                        count={5}
                        headline={
                            <span className='text-size-14 text-color-darker'>
                                <FormattedMessage id='intl-msg:chat.feedback.rating' />
                            </span>
                        }
                        leadingIcon={<span className='text-color-gray rioglyph rioglyph-thumbs-down' />}
                        trailingIcon={<span className='text-color-gray rioglyph rioglyph-thumbs-up' />}
                        onRatingChanged={handleRatingChanged}
                    />

                    <div className='margin-bottom-15 margin-top-10'>
                        <div className='text-color-darker text-medium margin-bottom-5 margin-right-20'>
                            <FormattedMessage id='intl-msg:chat.feedback.notes' />
                        </div>
                        <textarea
                            name='feedbackNotes'
                            className='form-control'
                            rows={4}
                            placeholder={intl.formatMessage({ id: 'intl-msg:chat.feedback.notes.placeholder' })}
                        />
                    </div>

                    <div className='btn-toolbar display-flex justify-content-end align-items-center margin-top-15'>
                        {rating === undefined ? (
                            <SimpleTooltip content='Please give a rating' placement='top'>
                                <div>
                                    <Button bsStyle='primary' type='submit' disabled>
                                        <FormattedMessage id='intl-msg:chat.feedback.sendButton' />
                                    </Button>
                                </div>
                            </SimpleTooltip>
                        ) : (
                            <Button bsStyle='primary' type='submit'>
                                <FormattedMessage id='intl-msg:chat.feedback.sendButton' />
                            </Button>
                        )}
                    </div>
                </form>
            )}
            {isSubmitted && (
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className='display-grid place-items-center margin-top-50 margin-x-20'
                >
                    <div className='panel panel-default border-color-lighter bg-decent position-relative width-100pct'>
                        <div
                            className={`position-absolute top-0pct left-50pct translate-x-50pct-y-50pct 
                                border border-color-lighter rounded-circle width-60 height-60
                                display-grid place-items-center bg-white`}
                        >
                            <span className='rioglyph rioglyph-ok text-color-success text-size-16 scale-150' />
                        </div>
                        <div className='panel-body padding-25'>
                            <div className='margin-top-20 text-center text-color-dark text-medium'>
                                <div className='margin-bottom-20'>
                                    <FormattedMessage id='intl-msg:chat.feedback.thanks' />
                                </div>
                                <Button
                                    bsSize='sm'
                                    bsStyle='primary'
                                    iconName='rioglyph-arrow-left'
                                    onClick={handleNavigateBackAfterSubmit}
                                >
                                    <FormattedMessage id='intl-msg:chat.feedback.backButton' />
                                </Button>
                            </div>
                        </div>
                    </div>
                </motion.div>
            )}
        </div>
    );
};
