import { useEffect, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import useOnlineStatus from '@rio-cloud/rio-uikit/useOnlineStatus';
import AppNavigationBar from '@rio-cloud/rio-uikit/AppNavigationBar';

import {
    useActiveChatId,
    useChatError,
    useGroupedChannelMessages,
    useIsChatInView,
    useIsChatNavigationEnabled,
    useIsInitialized,
    useSortedChannelMessages,
} from '../features/chat/chatSlice';
import { ChatLoadingState } from '../features/chat/ChatLoadingState';
import { ChatLoadingError } from '../features/chat/ChatLoadingError';
import { MessageInput } from '../features/messageInput/MessageInput';
import MessagesItemsOfDay from '../features/chat/MessageHistoryOfDay';
import { useSendbirdApi } from '../sendbird/useSendbirdApi';
import { EmptyChatState } from '../features/chat/EmptyChatState';
import { useAppContext } from '../layout/AppContext';
import Alert from '../components/Alert';
import { ChatMemberName } from '../components/ChatMemberName';
import { useEnterChat } from '../sendbird/hooks/useEnterChat';

const ChatIdNotProvided = () => (
    <div className='padding-15'>
        <FormattedMessage id='intl-msg:chat.label.provideAssetId' />
    </div>
);

const withHeader =
    (chatId: string | undefined, showHeader: boolean, navigateToDefault: VoidFunction) => (content: JSX.Element) => (
        <div className='display-flex flex-column height-100vh'>
            {showHeader && (
                <AppNavigationBar
                    title={chatId ? <ChatMemberName chatId={chatId} userId={chatId} /> : <div className='height-50' />}
                    className='border border-bottom-only position-fixed top-0 bg-white z-index-max height-50'
                    onNavigateBack={navigateToDefault}
                />
            )}
            {content}
        </div>
    );

export const MessageViewPage = () => {
    const messages = useSortedChannelMessages();
    const groupedMessages = useGroupedChannelMessages();
    const activeChatId = useActiveChatId();
    const isChatInitialized = useIsInitialized();
    const chatLoadingError = useChatError();
    const isOnline = useOnlineStatus();
    const isChatInView = useIsChatInView();

    const lastMessageCreatedAt = useMemo(() => {
        if (messages) {
            return messages.at(-1)?.createdAt;
        }
    }, [messages]);

    useEnterChat();

    const { sendMessage, markMessagesAsRead } = useSendbirdApi();

    const { navigateToDefault } = useAppContext();

    // Use url param "hideChatNavigation" to omit the navigation bar when
    // used in Livemonitor sidebar where navigation is not allowed
    const showNavigationHeader = useIsChatNavigationEnabled();

    const scrollToRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        scrollToRef.current?.scrollIntoView({
            behavior: 'instant',
        });
    }, [messages, isOnline]);

    useEffect(() => {
        if (isChatInView) {
            console.log('MessageViewPage: Marking messages as read');
            markMessagesAsRead();
        }
    }, [isChatInView, lastMessageCreatedAt]);

    const handleSendClicked = (message: string) => {
        sendMessage(message);
    };

    const handleNavigateBack = () => {
        navigateToDefault('from chat details page');
    };

    const renderWithHeader = withHeader(activeChatId, showNavigationHeader, handleNavigateBack);

    if (!activeChatId) {
        return renderWithHeader(<ChatIdNotProvided />);
    }

    if (chatLoadingError) {
        return renderWithHeader(
            <ChatLoadingError errorText={chatLoadingError.errorText} errorType={chatLoadingError.errorType} />
        );
    }

    if (!isChatInitialized) {
        return renderWithHeader(<ChatLoadingState />);
    }

    return renderWithHeader(
        <>
            <div className='flex-1-1 overflow-y-auto no-scrollbars'>
                <div className='display-flex flex-column gap-10 padding-x-15 padding-top-25'>
                    {groupedMessages?.length === 0 && <EmptyChatState />}
                    {groupedMessages?.map(groupedMessages => (
                        <MessagesItemsOfDay
                            key={groupedMessages.timestamp}
                            timestamp={groupedMessages.timestamp}
                            messages={groupedMessages.messages}
                        />
                    ))}
                </div>
                <div className='height-15' />
                <div ref={scrollToRef} />
            </div>
            {!isOnline && <Alert message={<FormattedMessage id={'intl-msg:chat.error.offline'} />} />}
            <MessageInput onSendClicked={handleSendClicked} sendEnabled={isOnline} />
        </>
    );
};
