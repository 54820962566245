import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { channelSelected, chatNavigationBarChanged } from '../features/chat/chatSlice';
import { parseRoute } from '../routes/routeHelper';
import { useAppDispatch } from '../configuration/setup/hooks';

export const useDeepLink = () => {
    const dispatch = useAppDispatch();

    const { search } = useLocation();

    useEffect(() => {
        const searchParams = parseRoute(search);
        if (searchParams.channelId) {
            dispatch(channelSelected(searchParams.channelId));
        }
        if (searchParams.hideChatNavigation !== undefined) {
            dispatch(chatNavigationBarChanged(!!searchParams.hideChatNavigation));
        }
    }, [search]);
};
