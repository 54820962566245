import { createHashRouter, createRoutesFromElements, Route } from 'react-router-dom';

import AppLayout from '../layout/AppLayout';

export const DEFAULT_ROUTE = '/';
export const NEW_CHAT_ROUTE = '/new';
export const CHAT_DETAILS_ROUTE = '/chatDetails';
export const BROADCAST_ROUTE = '/broadcast';
export const FEEDBACK_ROUTE = '/feedback';
export const HELP_ROUTE = '/help';

export const routes = [DEFAULT_ROUTE, NEW_CHAT_ROUTE, BROADCAST_ROUTE, CHAT_DETAILS_ROUTE, FEEDBACK_ROUTE, HELP_ROUTE];

export const router = createHashRouter(createRoutesFromElements(<Route path='/*' element={<AppLayout />} />));
