import { useState } from 'react';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import GroupedItemList from '@rio-cloud/rio-uikit/GroupedItemList';

import { type Chat, useGetChatsQuery } from '../services/chatAdministrationApi';
import { EmptyNewChatList } from '../features/newChatList/EmptyNewChatList';
import { useAppContext } from '../layout/AppContext';
import { RecipientListItem } from '../features/newChatList/RecipientListItem';
import { RecipientsFilter } from '../features/newChatList/RecipientsFilter';
import { NewChatHeader } from '../features/newChatList/NewChatHeader';

export const NewChatViewPage = () => {
    const { navigateToChat } = useAppContext();

    const [filterValue, setFilterValue] = useState('');

    const { data: chats = [], isLoading: isLoadingChats } = useGetChatsQuery();

    const filteredChats = chats.filter(chat => chat.name.toLowerCase().includes(filterValue.toLowerCase()));

    const showChats = !isLoadingChats && filteredChats.length > 0;
    const showEmptyScreen = !isLoadingChats && filteredChats.length === 0;

    const handleSelectChat = (channelId: string) => {
        navigateToChat(channelId, 'from new chat page to start a new chat');
    };

    const mappedChatsForGrouping = filteredChats.map(chat => ({
        ...chat,
        groupKey: chat.lastName || chat.name,
    }));

    return (
        <div className='display-flex flex-column height-100vh bg-lightest'>
            <div className='flex-1-1 overflow-y-auto no-scrollbars display-flex'>
                <NewChatHeader count={filteredChats.length} />

                <div className='flex-1-1 margin-top-50 position-relative'>
                    <RecipientsFilter onFilterChange={setFilterValue} />
                    {isLoadingChats && (
                        <div className='display-grid place-items-center height-100pct'>
                            <Spinner />
                        </div>
                    )}
                    {showChats && (
                        <GroupedItemList
                            groupBy='groupKey'
                            items={mappedChatsForGrouping}
                            renderDivider={(groupKey: string) => (
                                <div className='text-color-dark text-medium margin-bottom-10 padding-top-15 margin-x-20 text-size-16'>
                                    {groupKey}
                                </div>
                            )}
                            renderItem={(chat: Chat) => (
                                <RecipientListItem
                                    key={chat.id}
                                    recipient={chat}
                                    onClick={() => handleSelectChat(chat.id)}
                                />
                            )}
                            listElementClassName='list-group margin-x-15 rounded overflow-hidden shadow-accent divider-y-1 divider-color-light divider-style-solid'
                            className='margin-bottom-15 margin-top--10'
                        />
                    )}
                    {showEmptyScreen && (
                        <div className='margin-15 bg-white rounded display-flex justify-content-center'>
                            <div className='width-300'>
                                <EmptyNewChatList />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
