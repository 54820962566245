import { type KeyboardEvent, useEffect, useRef, useState, type ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@rio-cloud/rio-uikit/Button';
import noop from 'lodash/fp/noop';

import { gaPush, TRACKING_CATEGORIES } from '../../configuration/setup/googleAnalytics';

type MessageInputProps = {
    sendEnabled?: boolean;
    hideDisclaimer?: boolean;
    onSendClicked?: (message: string) => void;
};

export const MessageInput = (props: MessageInputProps) => {
    const { sendEnabled = true, hideDisclaimer = false, onSendClicked = noop } = props;

    const intl = useIntl();
    const ref = useRef<HTMLTextAreaElement>(null);

    const [message, setMessage] = useState('');
    const [isSendEnabled, setIsSendEnabled] = useState(false);

    const sanitizeMessage = (message: string) => message.trimEnd();

    useEffect(() => setIsSendEnabled(sendEnabled && !!sanitizeMessage(message)), [sendEnabled, message]);
    useEffect(() => ref.current?.focus(), [ref.current]);

    const doSendMessage = () => {
        // Cleanup the message with trailing spaces and newlines
        const sanitizedMessage = sanitizeMessage(message);
        if (sanitizedMessage) {
            onSendClicked(sanitizedMessage);
            setMessage('');
        }
    };

    const handleSendButton = () => {
        gaPush({
            category: TRACKING_CATEGORIES.CHAT,
            action: 'Send message',
            label: 'Clicked send message button',
        });
        doSendMessage();
    };

    const handleSendOnEnter = (event: KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !event.shiftKey && isSendEnabled) {
            gaPush({
                category: TRACKING_CATEGORIES.CHAT,
                action: 'Send message',
                label: 'Sent message via enter key',
            });
            doSendMessage();
        }
    };

    const handleValueChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        // don't allow leading spaces or leading newlines,
        // that's why we have to avoid entering it here
        const value = event.target.value.trimStart();
        setMessage(value);
    };

    const className = hideDisclaimer ? 'padding-bottom-15' : 'padding-bottom-5';
    return (
        <>
            <div className={`flex-0 display-flex gap-5 align-items-end padding-top-15 padding-x-15 ${className}`}>
                <div className='display-grid grid-stack width-100pct'>
                    <textarea
                        ref={ref}
                        id='chatInputText'
                        onKeyDown={handleSendOnEnter}
                        value={message}
                        onChange={handleValueChange}
                        className='form-control overflow-hidden grid-stack-item max-height-100'
                        placeholder={intl.formatMessage({ id: 'intl-msg:chat.placeholder.typeMessage' })}
                        rows={1}
                        style={{
                            resize: 'none',
                        }}
                    />
                    {/* Hidden element that has the same styling as the textarea but is not visible.
                    It has the same content as the textarea but it will increase it's height and hence auto resizes
                    the textarea with it since both are part of the same grid stack. */}
                    <div
                        className='grid-stack-item form-control height-auto white-space-pre-line max-height-100'
                        style={{
                            visibility: 'hidden',
                        }}
                    >
                        {message}
                    </div>
                </div>
                <Button
                    role='button'
                    bsStyle='primary'
                    iconName='rioglyph-send'
                    iconOnly
                    disabled={!isSendEnabled}
                    onClick={handleSendButton}
                    style={{ minWidth: '40px' }}
                    data-testid='sendButton'
                />
            </div>
            {!hideDisclaimer && (
                <div className={'padding-top-5 padding-bottom-15 padding-x-15'}>
                    <div className='bg-lightest border-none text-color-dark text-size-10 line-height-150rel padding-x-10 padding-y-5'>
                        <FormattedMessage id={'intl-msg:chat.deletionDisclaimer'} />
                    </div>
                </div>
            )}
        </>
    );
};
