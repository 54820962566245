import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import configReducer from './configSlice';
import loginReducer from '../login/loginSlice';
import langReducer from '../lang/langSlice';
import tokenReducer from '../tokenHandling/tokenSlice';
import appReducer from '../../data/appSlice';
import chatReducer from '../../features/chat/chatSlice';
import { chatAdministrationApi } from '../../services/chatAdministrationApi';

export const store = configureStore({
    reducer: {
        config: configReducer,
        lang: langReducer,
        app: appReducer,
        login: loginReducer,
        tokenHandling: tokenReducer,
        chat: chatReducer,

        [chatAdministrationApi.reducerPath]: chatAdministrationApi.reducer,
    },

    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(chatAdministrationApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
