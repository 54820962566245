import type { PropsWithChildren } from 'react';

type ListItemProps = {
    onClick: () => void;
    className?: string;
};

export const ListItem = (props: PropsWithChildren<ListItemProps>) => {
    const { onClick, className, children } = props;

    return (
        <div
            className={`${className} padding-x-10 padding-y-15 bg-white hover-bg-highlight-lightest cursor-pointer`}
            onClick={onClick}
        >
            <div className='display-flex justify-content-between gap-15 align-items-center width-100pct padding-left-5'>
                {children}
                <div>
                    <span className='rioglyph rioglyph rioglyph-chevron-right text-size-18 text-color-gray hover-text-color-primary' />
                </div>
            </div>
        </div>
    );
};
