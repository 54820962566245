import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import ClearableInput from '@rio-cloud/rio-uikit/ClearableInput';
import useEsc from '@rio-cloud/rio-uikit/useEsc';
import debounce from 'lodash/fp/debounce';

import { gaPush, TRACKING_CATEGORIES } from '../../configuration/setup/googleAnalytics';

const FILTER_DEBOUNCE = 100;

type RecipientFilterProps = {
    onFilterChange: (value: string) => void;
};

export const RecipientsFilter = ({ onFilterChange }: RecipientFilterProps) => {
    const [inputValue, setInputValue] = useState('');
    const [isDirty, setIsDirty] = useState(false);

    const intl = useIntl();

    const inputRef = useRef<HTMLInputElement>(null);

    const focusInput = () => inputRef.current?.focus();
    const handleClear = () => focusInput();

    const debouncedSetFilterValue = debounce(FILTER_DEBOUNCE, onFilterChange);

    const handleFilterChange = (value: string) => {
        setInputValue(value);
        debouncedSetFilterValue(value);

        if (value.length > 0 && !isDirty) {
            setIsDirty(true);
        }
    };

    useEffect(() => {
        if (isDirty) {
            gaPush({
                category: TRACKING_CATEGORIES.NEW_CHAT,
                action: 'Filter recipients',
                label: 'Used recipients filter input',
            });
        }
    }, [isDirty]);

    // clear filter input on esc
    useEsc(() => {
        if (inputRef.current === document.activeElement) {
            gaPush({
                category: TRACKING_CATEGORIES.NEW_CHAT,
                action: 'Clear filter',
                label: 'Cleared recipients filter input via esc key',
            });

            setInputValue('');
            onFilterChange('');
        }
    });

    return (
        <div className='margin-15'>
            <div className='input-group width-100pct'>
                <span className='input-group-addon'>
                    <span className='rioglyph rioglyph-search' aria-hidden='true' />
                </span>
                <ClearableInput
                    value={inputValue}
                    inputRef={inputRef}
                    placeholder={intl.formatMessage({ id: 'intl-msg:chat.new.filter.placeholder' })}
                    onChange={handleFilterChange}
                    onClear={handleClear}
                />
            </div>
        </div>
    );
};
