import { FormattedDate, FormattedMessage } from 'react-intl';
import { getTime, startOfDay } from 'date-fns';
import Divider from '@rio-cloud/rio-uikit/Divider';

type MessageDividerProps = {
    timestamp: number;
};

const MessageDivider = ({ timestamp }: MessageDividerProps) => {
    const date = new Date(timestamp);
    const isToday = getTime(startOfDay(new Date())) === timestamp;

    return (
        <Divider spacing={35} dividerColor='lighter'>
            <div className='tag tag-small border-color-lighter'>
                {isToday ? (
                    <FormattedMessage id='intl-msg:chat.label.today' />
                ) : (
                    <FormattedDate value={date} year='numeric' month='2-digit' day='numeric' />
                )}
            </div>
        </Divider>
    );
};

export default MessageDivider;
