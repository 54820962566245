import { FormattedMessage } from 'react-intl';
import Fade from '@rio-cloud/rio-uikit/Fade';
import noop from 'lodash/fp/noop';

import { MessageTranslationErrorTooltip } from './MessageTranslationErrorTooltip';

type MessageTranslationProps = {
    isLoading: boolean;
    isError: boolean;
    showOriginalButton: boolean;
    onFetchTranslation?: () => void;
    onRetryFetchTranslation?: () => void;
    onShowOriginalMessage?: () => void;
};

export const MessageTranslation = (props: MessageTranslationProps) => {
    const {
        isLoading,
        isError,
        showOriginalButton,
        onShowOriginalMessage = noop,
        onFetchTranslation = noop,
        onRetryFetchTranslation = noop,
    } = props;

    const showOriginalMessageButton = showOriginalButton && !isLoading && !isError;
    const showTranslationButton = !showOriginalButton && !isLoading && !isError;

    return (
        <Fade initial={false} exitBeforeEnter duration={0.2}>
            {showOriginalMessageButton && (
                <div key='orgMessageButton' className='height-15 margin-bottom-1'>
                    <div
                        className='margin-x-10 text-color-darker hover-text-color-highlight-darker text-size-11 cursor-pointer display-flex align-items-center gap-2'
                        onClick={onShowOriginalMessage}
                    >
                        <span className='rioglyph rioglyph-language margin-top--2' />
                        <span>
                            <FormattedMessage id='intl-msg:chat.message.label.orgMessage' />
                        </span>
                    </div>
                </div>
            )}
            {showTranslationButton && (
                <div key='translationButton' className='height-15 margin-bottom-1'>
                    <div
                        className='margin-x-10 text-color-primary hover-text-color-highlight-darker text-size-11 cursor-pointer display-flex align-items-center gap-2'
                        onClick={onFetchTranslation}
                    >
                        <span className='rioglyph rioglyph-language margin-top--2' />
                        <span>
                            <FormattedMessage id='intl-msg:chat.message.label.translate' />
                        </span>
                    </div>
                </div>
            )}
            {isLoading && (
                <div key='loadingState' className='margin-x-10 text-size-11'>
                    <FormattedMessage id='intl-msg:chat.message.label.translating' />
                </div>
            )}
            {isError && (
                <div key='errorState' className='margin-x-10 display-flex gap-5 text-size-11'>
                    <div className='text-color-danger'>
                        <FormattedMessage id='intl-msg:chat.message.label.translateFailed' />
                    </div>
                    <MessageTranslationErrorTooltip>
                        <div
                            className='text-color-primary hover-text-color-highlight-darker cursor-pointer'
                            onClick={onRetryFetchTranslation}
                        >
                            <span className='rioglyph rioglyph-refresh' />
                        </div>
                    </MessageTranslationErrorTooltip>
                </div>
            )}
        </Fade>
    );
};
