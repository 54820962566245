type ChatIconProps = {
    type?: string;
    messageCount?: number;
};

const getIconName = (type: string) => (type === 'asset' ? 'rioglyph-truck' : 'rioglyph-driver');

export const ChatIcon = ({ type = 'asset', messageCount = 0 }: ChatIconProps) => (
    <div className='ChatIcon bg-white rounded-circle aspect-ratio-1 display-grid place-items-center border max-width-40 width-40 position-relative'>
        <span className={`rioglyph ${getIconName(type)} text-color-darker text-size-h4`} />
        {messageCount > 0 && (
            <span className='position-absolute top-5 right-5 badge badge-indicator badge-indicator-border badge-primary'>
                {messageCount}
            </span>
        )}
    </div>
);
