import { FormattedMessage } from 'react-intl';
import AppNavigationBar from '@rio-cloud/rio-uikit/AppNavigationBar';

import BlueDoubleCheckmarkIcon from '../assets/read.svg?react';
import GreyDoubleCheckmarkIcon from '../assets/received.svg?react';
import GreySingleCheckmarkIcon from '../assets/sent.svg?react';
import { useAppContext } from '../layout/AppContext';

export const HelpPage = () => {
    const { navigateToDefault } = useAppContext();

    const handleNavigateBack = () => navigateToDefault('from help page');

    return (
        <div className='display-flex flex-column height-100vh'>
            <AppNavigationBar
                title={<FormattedMessage id='intl-msg:chat.help.title' />}
                className='flex-0 border border-bottom-only bg-white z-index-max height-50'
                onNavigateBack={handleNavigateBack}
            />
            <div className='flex-1-1 padding-20 overflow-y-auto'>
                <div className='text-medium text-size-16 margin-bottom-15'>
                    <FormattedMessage id='intl-msg:chat.help.faq' />
                </div>
                <ul className='text-color-darker space-y-25'>
                    <li>
                        <div className='display-flex flex-column gap-5'>
                            <div className='text-medium text-color-darkest'>
                                <FormattedMessage id='intl-msg:chat.help.missingChats.title' />
                            </div>
                            <div>
                                <FormattedMessage id='intl-msg:chat.help.missingChats.description' />
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='display-flex flex-column gap-5'>
                            <div className='text-medium text-color-darkest'>
                                <FormattedMessage id='intl-msg:chat.help.readConfirmation.title' />
                                Woher weiß ich ob Nachrichten gelesen wurden?
                            </div>
                            <div>
                                <FormattedMessage id='intl-msg:chat.help.readConfirmation.description' />
                            </div>
                            <div className='bg-decent rounded border border-color-lightest margin-bottom-10 padding-y-10 padding-x-15 text-color-darker'>
                                <FormattedMessage
                                    id='intl-msg:chat.assetReadStatusTooltip'
                                    values={{
                                        h1: chunk => <div className='margin-bottom-5'>{chunk}</div>,
                                        h2: chunk => (
                                            <div className='display-flex'>
                                                <div className='width-20'>
                                                    <GreySingleCheckmarkIcon />
                                                </div>
                                                <div>{chunk}</div>
                                            </div>
                                        ),
                                        h3: chunk => (
                                            <div className='display-flex'>
                                                <div className='width-20'>
                                                    <GreyDoubleCheckmarkIcon />
                                                </div>
                                                <div>{chunk}</div>
                                            </div>
                                        ),
                                        h4: chunk => (
                                            <div className='display-flex'>
                                                <div className='width-20'>
                                                    <BlueDoubleCheckmarkIcon />
                                                </div>
                                                <div>{chunk}</div>
                                            </div>
                                        ),
                                    }}
                                />
                            </div>
                            <div className='bg-decent rounded border border-color-lightest margin-bottom-10 padding-y-10 padding-x-15 text-color-darker'>
                                <FormattedMessage
                                    id='intl-msg:chat.driverReadStatusTooltip'
                                    values={{
                                        h1: chunk => <div className='margin-bottom-5'>{chunk}</div>,
                                        h2: chunk => (
                                            <div className='display-flex'>
                                                <div className='width-20'>
                                                    <GreySingleCheckmarkIcon />
                                                </div>
                                                <div>{chunk}</div>
                                            </div>
                                        ),
                                        h3: chunk => (
                                            <div className='display-flex'>
                                                <div className='width-20'>
                                                    <GreyDoubleCheckmarkIcon />
                                                </div>
                                                <div>{chunk}</div>
                                            </div>
                                        ),
                                        h4: chunk => (
                                            <div className='display-flex'>
                                                <div className='width-20'>
                                                    <BlueDoubleCheckmarkIcon />
                                                </div>
                                                <div>{chunk}</div>
                                            </div>
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};
