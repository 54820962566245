import { ChatMemberName } from './ChatMemberName';
import type { Message } from '../features/chat/chatSlice';
import { ChatIcon } from './ChatIcon';
import { ChatListItemLastMessage } from './ChatListItemLastMessage';
import { ListItem } from './ListItem';

type ChatListItemProps = {
    id: string;
    type?: string;
    name?: string | JSX.Element;
    messageCount?: number;
    lastMessage?: Message;
    onClick: () => void;
};

export const ChatListItem = (props: ChatListItemProps) => {
    const { id, type, name, messageCount = 0, lastMessage, onClick } = props;

    return (
        <ListItem className='ChatListItem' onClick={onClick}>
            <>
                <ChatIcon type={type} messageCount={messageCount} />
                <div className='flex-1-1'>
                    <div className='text-size-14 line-height-125rel text-medium ellipsis-2'>
                        {name || <ChatMemberName userId={id} chatId={id} />}
                    </div>
                    <ChatListItemLastMessage chatId={id} lastMessage={lastMessage} />
                </div>
            </>
        </ListItem>
    );
};
