import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
    BROADCAST_ROUTE,
    CHAT_DETAILS_ROUTE,
    DEFAULT_ROUTE,
    FEEDBACK_ROUTE,
    HELP_ROUTE,
    NEW_CHAT_ROUTE,
} from '../routes/Router';

export const useNavigationDeepLink = (callback: (newLocation: string) => void) => {
    const { pathname } = useLocation();

    useEffect(() => {
        let newRoute = '';

        switch (pathname) {
            case CHAT_DETAILS_ROUTE:
                newRoute = CHAT_DETAILS_ROUTE;
                break;
            case NEW_CHAT_ROUTE:
                newRoute = NEW_CHAT_ROUTE;
                break;
            case BROADCAST_ROUTE:
                newRoute = BROADCAST_ROUTE;
                break;
            case FEEDBACK_ROUTE:
                newRoute = FEEDBACK_ROUTE;
                break;
            case HELP_ROUTE:
                newRoute = HELP_ROUTE;
                break;
            default:
                newRoute = DEFAULT_ROUTE;
                break;
        }

        callback(newRoute);
    }, [pathname]);
};
