import { useState, useEffect } from 'react';
import { useLazyGetTranslationsQuery } from '../services/chatAdministrationApi';
import { useAppDispatch } from '../configuration/setup/hooks';
import { messageTranslated } from '../features/chat/chatSlice';
import { gaPush, TRACKING_CATEGORIES } from '../configuration/setup/googleAnalytics';
import isEmpty from 'lodash/fp/isEmpty';

const getShortLocale = (locale: string) => locale.split('-')[0];

type UseTranslationsProps = {
    chatId: string;
    messageId: number;
    locale: string;
    translations: Record<string, string>;
};

export const useTranslations = ({ chatId, messageId, locale, translations }: UseTranslationsProps) => {
    const translation = translations[getShortLocale(locale)];

    const [showOriginalMessage, setShowOriginalMessage] = useState(false);
    const [showOriginalMessageButton, setShowOriginalMessageButton] = useState(!isEmpty(translation));

    const dispatch = useAppDispatch();

    const [getTranslation, { data, isLoading, error }] = useLazyGetTranslationsQuery();

    useEffect(() => {
        if (data) {
            dispatch(messageTranslated({ chatId, messageId, translations: data.translations }));
            setShowOriginalMessage(false);
            setShowOriginalMessageButton(true);
        }
    }, [data, dispatch, chatId, messageId]);

    const doFetchTranslation = () => {
        if (!translation) {
            gaPush({
                category: TRACKING_CATEGORIES.TRANSLATIONS,
                action: 'Translation requested',
                label: 'Fetched translation from backend',
            });
            getTranslation({ chatId, messageId, locale });
        }
        setShowOriginalMessage(false);
        setShowOriginalMessageButton(true);
    };

    const handleFetchTranslation = () => {
        gaPush({
            category: TRACKING_CATEGORIES.TRANSLATIONS,
            action: 'Translate button clicked',
            label: 'Clicked button to show translation',
        });
        doFetchTranslation();
    };

    const handleRetryFetchTranslation = () => {
        gaPush({
            category: TRACKING_CATEGORIES.TRANSLATIONS,
            action: 'Retry button clicked',
            label: 'Retried button clicked after failed translation',
        });
        doFetchTranslation();
    };

    const handleShowOriginalMessage = () => {
        gaPush({
            category: TRACKING_CATEGORIES.TRANSLATIONS,
            action: 'Show original button clicked',
            label: 'Clicked button to show original message',
        });
        setShowOriginalMessage(true);
        setShowOriginalMessageButton(false);
    };

    return {
        showOriginalMessage,
        showOriginalMessageButton,
        isLoading,
        error,
        handleFetchTranslation,
        handleRetryFetchTranslation,
        handleShowOriginalMessage,
        translation,
    };
};
