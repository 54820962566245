import { useReaction } from './chatSlice';

export const MessageReaction = ({ channelId, messageId }: { channelId: string; messageId: number }) => {
    const reaction = useReaction(channelId, messageId);

    if (!reaction || reaction.length === 0) {
        return null;
    }

    return (
        <div className='MessageReaction max-width-100pct position-relative user-select-none'>
            <div className='position-absolute right--20 bottom--20 bg-white padding-x-3 margin-top-2 rounded-circle min-height-30 min-width-30 text-size-18 text-color-darker align-items-center justify-content-center gap-5'>
                {reaction[reaction.length - 1].value}
            </div>
        </div>
    );
};
