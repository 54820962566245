import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';

export const ChatLoadingState = () => {
    return (
        <div className='padding-15 display-flex flex-column gap-25'>
            <div className='max-width-60pct'>
                <ContentLoader className='height-20 margin-bottom-10 max-width-40pct' />
                <ContentLoader className='height-80 margin-bottom-10' />
            </div>
            <div className='max-width-60pct width-100pct align-self-end'>
                <div className='display-flex flex-column align-items-end'>
                    <ContentLoader className='height-20 margin-bottom-10 max-width-40pct' />
                    <ContentLoader className='height-80 margin-bottom-10' />
                    <ContentLoader className='height-80 margin-bottom-10' />
                </div>
            </div>
            <div className='max-width-60pct'>
                <ContentLoader className='height-20 margin-bottom-10 max-width-40pct' />
                <ContentLoader className='height-80 margin-bottom-10' />
            </div>
        </div>
    );
};
