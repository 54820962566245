import { FormattedMessage } from 'react-intl';
import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';
import Tag from '@rio-cloud/rio-uikit/Tag';

import { useGetChatMemberQuery } from '../services/chatAdministrationApi';
import { ChatMemberInlineIcon } from './ChatMemberInlineIcon';
import { DriverNameFormat } from './DriverNameFormat';

type ChatMemberNameProps = {
    chatId: string;
    userId: string;
    showIcon?: boolean;
    showLicensePlate?: boolean;
    hasDriverFormat?: boolean;
};

export const ChatMemberName = (props: ChatMemberNameProps) => {
    const { chatId, userId, showIcon = false, showLicensePlate = true, hasDriverFormat = false } = props;

    const { data: chatMember, isLoading, isError } = useGetChatMemberQuery({ chatId, userId });

    if (isLoading) {
        return <ContentLoader width={200} />;
    }

    if (isError || !chatMember) {
        return <FormattedMessage id='intl-msg:chat.usertype.unknown' />;
    }

    const { name, firstName, lastName, licensePlate } = chatMember;

    if (licensePlate && showLicensePlate) {
        return (
            <div>
                <span>{name}</span>
                <Tag size='small' round={false} className='padding-x-3 padding-y-2 margin-left-5 scale-90'>
                    {licensePlate}
                </Tag>
            </div>
        );
    }

    if (showIcon) {
        return (
            <div className='margin-bottom-5 display-flex gap-5'>
                <ChatMemberInlineIcon memberType={chatMember.type} />
                {name}
            </div>
        );
    }

    if (hasDriverFormat) {
        return firstName && lastName ? <DriverNameFormat firstName={firstName} lastName={lastName} /> : name;
    }

    return name;
};
