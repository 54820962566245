import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    type GroupChannel,
    type MessageCollection,
    MessageCollectionInitPolicy,
    type MessageEventContext,
    MessageFilter,
} from '@sendbird/chat/groupChannel';
import { type BaseMessage, ReactionEventOperation } from '@sendbird/chat/message';
import { isAfter, subHours } from 'date-fns';

import { toDeliveryReceipt, toMessage, toReadReceipt, toSliceReaction } from './mapper';
import {
    deliveryReceiptsUpdateReceived,
    type Message,
    messagesAdded,
    readReceiptsUpdateReceived,
    type Receipt,
    updateReactions,
} from '../../features/chat/chatSlice';

export interface MessagesAndReceipts {
    messages: Message[];
    readReceipts: Receipt[];
    deliveryReceipts: Receipt[];
}

export const useMessageApi = () => {
    const dispatch = useDispatch();

    const [messageCollection, setMessageCollection] = useState<MessageCollection | null>(null);

    const dispatchMessageEvents = (channel: GroupChannel, userMessages: BaseMessage[]) => {
        const deliveryStatuses = Object.values(channel.getDeliveryStatus?.(false) || {});
        const messagesAndReceipts = userMessages.reduce<MessagesAndReceipts>(
            (agg, userMessage) => {
                if (!(userMessage.isUserMessage() || userMessage.isAdminMessage())) {
                    console.warn('Got an unsupported message type', userMessage);
                    return agg;
                }
                if (!isAfter(userMessage.createdAt, subHours(new Date(), 72))) {
                    console.warn('Message too old', userMessage);
                    return agg;
                }
                const readMembers = channel.getReadMembers?.(userMessage, false);
                agg.messages.push(toMessage(userMessage));
                agg.readReceipts.push(toReadReceipt(userMessage, readMembers));
                agg.deliveryReceipts.push(toDeliveryReceipt(userMessage, deliveryStatuses));
                return agg;
            },
            { messages: [], readReceipts: [], deliveryReceipts: [] }
        );

        dispatch(
            deliveryReceiptsUpdateReceived({
                channelId: channel.url,
                receipts: messagesAndReceipts.deliveryReceipts,
            })
        );
        dispatch(
            readReceiptsUpdateReceived({
                channelId: channel.url,
                receipts: messagesAndReceipts.readReceipts,
            })
        );
        dispatch(messagesAdded(messagesAndReceipts.messages));

        userMessages.forEach(message => {
            message.reactions.forEach(sendbirdReaction => {
                const operation: ReactionEventOperation = sendbirdReaction.isEmpty
                    ? ReactionEventOperation.DELETE
                    : ReactionEventOperation.ADD;
                const reaction = toSliceReaction(sendbirdReaction);
                dispatch(
                    updateReactions({
                        channelId: channel.url,
                        messageId: message.messageId,
                        operation,
                        reaction,
                    })
                );
            });
        });
    };

    const registerMessageHandlers = (groupChannel: GroupChannel) => {
        const collection = groupChannel.createMessageCollection({
            filter: new MessageFilter(),
            startingPoint: Date.now(),
            prevResultLimit: 200, // maximum allowed value
            nextResultLimit: 20,
        });

        collection
            .initialize(MessageCollectionInitPolicy.CACHE_AND_REPLACE_BY_API)
            .onApiResult(async (err: Error | null, messages: BaseMessage[] | null) => {
                if (!messages || err) {
                    return;
                }
                dispatchMessageEvents(groupChannel, messages);
                // If we get new messages via API, we can assume that the customer has seen them since a chat has been opened.
                await groupChannel.markAsRead();
            });

        collection?.setMessageCollectionHandler({
            onMessagesAdded: async (
                _: MessageEventContext,
                receivingChannel: GroupChannel,
                messages: BaseMessage[]
            ) => {
                dispatchMessageEvents(receivingChannel, messages);

                // Only mark as delivered, if we get new chat messages via websocket.
                // We don't know yet, which view is open and if the customer has seen the messages.
                await receivingChannel.markAsDelivered();
            },
            onMessagesUpdated: async (_, channel, messages) => {
                dispatchMessageEvents(channel, messages);
            },
        });
        setMessageCollection(collection);
        return collection;
    };

    const unregisterMessageHandlers = () => {
        console.log('Unregistering message handlers', messageCollection);
        if (messageCollection) {
            messageCollection.dispose();
            console.log(`Disposed message collection for chat ${messageCollection.channel.url}`);
        }
    };

    return {
        registerMessageHandlers,
        unregisterMessageHandlers,
    };
};
