import { useMemo } from 'react';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import isEmpty from 'lodash/fp/isEmpty';

import { ChatListItem } from '../components/ChatListItem';
import { useAppContext } from '../layout/AppContext';
import { useChatError, useGroupChannels, useIsInitialized } from '../features/chat/chatSlice';
import { EmptyChatList } from '../features/chatList/EmptyChatList';
import { ChatLoadingError } from '../features/chat/ChatLoadingError';
import { ChatListHeader } from '../features/newChatList/ChatListHeader';
import { Footer } from '../features/footer/Footer';

export const ChatListViewPage = () => {
    const { navigateToChat } = useAppContext();

    const groupChannels = useGroupChannels();
    const isInitialized = useIsInitialized();
    const hasErrors = useChatError();

    const handleSelectChat = (channelId: string) => {
        navigateToChat(channelId, 'from chat list page');
    };

    const filteredChannels = useMemo(() => groupChannels.filter(channel => channel.lastMessage), [groupChannels]);

    const showSpinner = !isInitialized && !hasErrors;
    const showChannels = isInitialized && !isEmpty(filteredChannels);
    const showEmptyScreen = isInitialized && isEmpty(filteredChannels) && !showSpinner;

    if (hasErrors) {
        return <ChatLoadingError errorType={hasErrors.errorType} errorText={hasErrors.errorText} />;
    }

    return (
        <div className='display-flex flex-column height-100vh'>
            <ChatListHeader openChatsCount={filteredChannels.length} />
            <div className='flex-1-1'>
                {showSpinner && <Spinner />}

                {showChannels && (
                    <div className='border border-bottom-only divider-y-1 divider-color-light divider-style-solid overflow-y-auto no-scrollbars position-relative'>
                        {filteredChannels.map(groupChannel => (
                            <ChatListItem
                                key={groupChannel.channelId}
                                id={groupChannel.channelId}
                                type={groupChannel.channelId.split('_')[0]}
                                messageCount={groupChannel.unreadMessageCount}
                                lastMessage={groupChannel.lastMessage}
                                onClick={() => handleSelectChat(groupChannel.channelId)}
                            />
                        ))}
                    </div>
                )}

                {showEmptyScreen && (
                    <div className='no-scrollbars position-relative'>
                        <EmptyChatList />
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};
