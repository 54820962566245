import { createContext, useContext } from 'react';
import type { ChatConnection } from './types';

const emptyChatConnection: ChatConnection = {
    sendMessage: (_: string) => Promise.resolve(),
    markMessagesAsRead: () => Promise.resolve(),
    disconnect: () => Promise.resolve(),
    reconnect: () => Promise.resolve(),
    resetChatErrors: () => {},
};

const UseSendbirdApi = createContext<ChatConnection>(emptyChatConnection);

export const SendbirdProvider = UseSendbirdApi.Provider;

export const useSendbirdApi = () => useContext(UseSendbirdApi);
