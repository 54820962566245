import type { ReactNode } from 'react';

interface AlertProps {
    message: string | ReactNode;
}

const Alert = ({ message }: AlertProps) => (
    <div className='alert alert-warning margin-x-15 padding-10'>
        <div className='display-flex gap-10'>
            <span className='rioglyph rioglyph-warning-sign text-size-18 text-color-warning padding-right-5' />
            <span>{message}</span>
        </div>
    </div>
);

export default Alert;
