import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { chatErrorResetted, useActiveChatId, useIsChatInView, useIsInitialized } from '../../features/chat/chatSlice';
import { sendbird } from '../client/sendbird';
import { useChatErrorHandlers } from './useChatErrorHandlers';
import { useEnterChatMutation } from '../../services/chatAdministrationApi';
import { useMessageApi } from './useMessageApi';
import { useSendbirdApi } from '../useSendbirdApi';

export const useEnterChat = () => {
    const dispatch = useDispatch();

    const initialized = useIsInitialized();
    const activeChatId = useActiveChatId();
    const isChatInView = useIsChatInView();

    const [enterChatMutation] = useEnterChatMutation();
    const { registerMessageHandlers, unregisterMessageHandlers } = useMessageApi();
    const { handleError } = useChatErrorHandlers();
    const { markMessagesAsRead, resetChatErrors } = useSendbirdApi();

    const enterChat = async (chatId: string) => {
        try {
            dispatch(chatErrorResetted());
            await enterChatMutation(chatId).unwrap();
            const enteredChannel = await sendbird.groupChannel.getChannel(chatId);
            const collection = registerMessageHandlers(enteredChannel);
            console.log(`Registered message collection for chat ${collection.channel.url}`);

            return collection;
        } catch (error) {
            handleError(error);
        }
    };

    const leaveChat = () => {
        unregisterMessageHandlers();
        resetChatErrors();
    };

    useEffect(() => {
        if (initialized && activeChatId) {
            enterChat(activeChatId);
        }

        return () => {
            leaveChat();
        };
    }, [initialized, activeChatId]);

    useEffect(() => {
        // This effect is triggered if the user has a chat view open, closes it and then reopens it.
        // If messages are received in the meantime, they are marked as read.
        console.log('is chat in view changed', isChatInView);

        if (initialized && activeChatId && isChatInView) {
            console.log('mark as read because of isChatInView change');
            markMessagesAsRead();
        }
    }, [initialized, activeChatId, isChatInView]);
};
