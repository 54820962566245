import type { Message } from './chatSlice';
import MessageHistory from './MessageHistory';
import MessageDivider from './MessageDivider';

type MessageItemsOfDayProps = {
    timestamp: number;
    messages: Message[];
};

const MessageHistoryOfDay = ({ timestamp, messages }: MessageItemsOfDayProps) => {
    return (
        <div key={timestamp}>
            <MessageDivider timestamp={timestamp} />
            <MessageHistory messages={messages} />
        </div>
    );
};

export default MessageHistoryOfDay;
