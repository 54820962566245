import useEffectOnce from '@rio-cloud/rio-uikit/useEffectOnce';

import { useDispatch } from 'react-redux';
import { type GroupChannel, GroupChannelHandler } from '@sendbird/chat/groupChannel';
import type { BaseChannel } from '@sendbird/chat';
import type { ReactionEvent } from '@sendbird/chat/message';

import { sendbird } from '../client/sendbird';
import {
    deliveryReceiptsUpdateReceived,
    type Reaction,
    readReceiptsUpdateReceived,
    updateReactions,
} from '../../features/chat/chatSlice';
import { toDeliveryReceipt, toReadReceipt } from './mapper';

const GROUP_CHANNEL_HANDLER_ID = 'group-channel-handler';

export const useMessageEventHandler = () => {
    const dispatch = useDispatch();

    useEffectOnce(() => {
        console.log('Globally register delivery-receipt-handler');
        sendbird.groupChannel.addGroupChannelHandler(
            GROUP_CHANNEL_HANDLER_ID,
            new GroupChannelHandler({
                onUnreadMemberStatusUpdated: async (channel: GroupChannel) => {
                    if (channel.lastMessage === null) {
                        return;
                    }
                    const userMessages = await channel.getMessagesByMessageId(channel.lastMessage.messageId, {
                        prevResultSize: 10,
                        nextResultSize: 0,
                        isInclusive: true,
                    });
                    dispatch(
                        readReceiptsUpdateReceived({
                            channelId: channel.url,
                            receipts: userMessages.map(userMessage =>
                                toReadReceipt(userMessage, channel.getReadMembers?.(userMessage, false))
                            ),
                        })
                    );
                },
                onUndeliveredMemberStatusUpdated: async channel => {
                    const deliveryStatusForChannelMap = channel.getDeliveryStatus(false);

                    if (!channel.lastMessage || !deliveryStatusForChannelMap) {
                        return;
                    }
                    const userMessages = await channel.getMessagesByMessageId(channel.lastMessage.messageId, {
                        prevResultSize: 20,
                        nextResultSize: 0,
                        isInclusive: true,
                    });
                    const deliveryStatuses = Object.values(deliveryStatusForChannelMap || {});
                    dispatch(
                        deliveryReceiptsUpdateReceived({
                            channelId: channel.url,
                            receipts: userMessages.map(userMessage => toDeliveryReceipt(userMessage, deliveryStatuses)),
                        })
                    );
                },
                onReactionUpdated: (channel: BaseChannel, reactionEvent: ReactionEvent) => {
                    if (!reactionEvent.operation) {
                        return;
                    }
                    const reaction: Reaction = {
                        value: reactionEvent.key,
                        user: {
                            userId: reactionEvent.userId,
                            nickname: reactionEvent.userId,
                        },
                    };
                    dispatch(
                        updateReactions({
                            channelId: channel.url,
                            messageId: reactionEvent.messageId,
                            operation: reactionEvent.operation,
                            reaction,
                        })
                    );
                },
            })
        );
        return () => {
            console.log('Globally unregister delivery-receipt-handler');
            sendbird.groupChannel.removeGroupChannelHandler(GROUP_CHANNEL_HANDLER_ID);
        };
    });
};
