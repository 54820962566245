import { FormattedMessage } from 'react-intl';
import SimpleTooltip from '@rio-cloud/rio-uikit/SimpleTooltip';
import type { PropsWithChildren } from 'react';

export const MessageTranslationErrorTooltip = ({ children }: PropsWithChildren) => {
    return (
        <SimpleTooltip
            content={<FormattedMessage id='intl-msg:chat.message.label.translationFailedTooltip' />}
            placement='bottom'
            delay={{ show: 800, hide: 500 }}
            popperConfig={{
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 5],
                        },
                    },
                    {
                        name: 'arrow',
                        options: {},
                    },
                ],
            }}
        >
            {children}
        </SimpleTooltip>
    );
};
