import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareHeaders } from '../configuration/tokenHandling/prepareHeaders';

export type Token = {
    token: string;
    userId: string;
};

export type ChatMemberType = 'asset' | 'backoffice' | 'driver';

export type Chat = {
    id: string;
    name: string;
    firstName?: string;
    lastName?: string;
    type: ChatMemberType;
};

export type ChatMember = {
    id: string;
    name?: string;
    firstName?: string;
    lastName?: string;
    type: ChatMemberType;
    licensePlate?: string;
};

export type BroadcastMessage = {
    message: string;
    groups: string[];
};

export type GetChatMemberQuery = {
    chatId: string;
    userId: string;
};

export type TranslatedMessage = {
    chatId: string;
    messageId: number;
    translations: { [key: string]: string };
};

export type GetTranslationQuery = {
    chatId: string;
    messageId: number;
    locale: string;
};

export const chatAdministrationApi = createApi({
    reducerPath: 'chatAdministrationApi',
    baseQuery: fetchBaseQuery({ baseUrl: import.meta.env.VITE_ORDER_COMMUNICATION_CHAT_BACKEND, prepareHeaders }),
    endpoints: builder => ({
        requestToken: builder.mutation<Token, void>({
            query: () => ({
                method: 'POST',
                url: '/token',
                responseHandler: async (response: Response): Promise<Token> => {
                    const data = await response.json();
                    return {
                        token: data.token,
                        userId: data.user_id,
                    };
                },
            }),
        }),
        enterChat: builder.mutation<void, string>({
            query: chatId => ({
                method: 'POST',
                url: `/chats/${chatId}/members`,
            }),
        }),
        getChats: builder.query<Chat[], void>({
            query: () => ({
                url: '/chats',
                responseHandler: async (response: Response): Promise<Chat[]> => {
                    const data = await response.json();
                    const chats: Chat[] = data.items.map(
                        // biome-ignore lint/suspicious/noExplicitAny: Basically a rest user which we dont' have yet
                        (chat: any): Chat => ({
                            id: chat.id,
                            name: chat.name,
                            firstName: chat.first_name,
                            lastName: chat.last_name,
                            type: chat.type,
                        })
                    );
                    return chats.sort((a, b) => a.name.localeCompare(b.name));
                },
            }),
        }),
        getChatMember: builder.query<ChatMember, GetChatMemberQuery>({
            query: ({ chatId, userId }) => ({
                url: `/chats/${chatId}/members/${userId}`,
                responseHandler: async (response: Response): Promise<ChatMember> => {
                    const data = await response.json();
                    return {
                        id: data.id,
                        type: data.type,
                        name: data.name,
                        firstName: data.first_name,
                        lastName: data.last_name,
                        licensePlate: data.license_plate,
                    };
                },
            }),
        }),
        sendBroadcastMessage: builder.mutation<void, BroadcastMessage>({
            query: ({ message, groups }) => ({
                method: 'POST',
                url: '/broadcasts',
                body: {
                    message,
                    groups,
                },
            }),
        }),
        getTranslations: builder.query<TranslatedMessage, GetTranslationQuery>({
            query: ({ chatId, messageId, locale }) => ({
                url: `/chats/${chatId}/messages/${messageId}/translations?locale=${locale}`,
            }),
        }),
    }),
});

export const {
    useRequestTokenMutation,
    useSendBroadcastMessageMutation,
    useEnterChatMutation,
    useGetChatsQuery,
    useGetChatMemberQuery,
    useLazyGetTranslationsQuery,
} = chatAdministrationApi;
