import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Fade from '@rio-cloud/rio-uikit/Fade';
import Button from '@rio-cloud/rio-uikit/Button';
import useKey from '@rio-cloud/rio-uikit/useKey';
import useEsc from '@rio-cloud/rio-uikit/useEsc';

type BroadcastConfirmationProps = {
    show: boolean;
    totalRecipients: number;
    onConfirm: () => void;
    onCancel: () => void;
};

export const BroadcastConfirmation = ({ show, totalRecipients, onConfirm, onCancel }: BroadcastConfirmationProps) => {
    const [isVisible, setIsVisible] = useState(false);

    // Handle visibility differently than show prop as the component is always mounted and the
    // key listener are already registered. This avoids confirming the modal before it was even shown
    const [previousShow, setPreviousShow] = useState(show);
    if (!previousShow && show) {
        setPreviousShow(show);
        setTimeout(() => {
            setIsVisible(true);
        }, 500);
    }

    if (previousShow && !show) {
        setPreviousShow(show);
        setTimeout(() => {
            setIsVisible(false);
        }, 500);
    }

    useEsc(() => isVisible && onCancel());
    useKey(event => event.key === 'Enter' && isVisible && onConfirm());

    return (
        <>
            <Fade show={show}>
                <div className='position-absolute inset-0 bg-darkest opacity-30' />
            </Fade>
            <Fade show={show} animationStyle='fromBottom'>
                <div className='position-absolute bottom-0 width-100pct padding-15'>
                    <div className='padding-15 bg-white rounded'>
                        <div className='margin-bottom-25 margin-top-10 text-size-16 text-center'>
                            <FormattedMessage
                                id='intl-msg:chat.broadcast.sendConfirmationTitle'
                                values={{
                                    count: totalRecipients,
                                }}
                            />
                        </div>
                        <div className='display-flex gap-15 flex-row'>
                            <Button block onClick={onCancel}>
                                <FormattedMessage id='intl-msg:chat.broadcast.cancelSend' />
                            </Button>
                            <Button block bsStyle='primary' onClick={onConfirm} iconName='rioglyph-send'>
                                <FormattedMessage id='intl-msg:chat.broadcast.confirmSend' />
                            </Button>
                        </div>
                    </div>
                </div>
            </Fade>
        </>
    );
};
