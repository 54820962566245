import type { ChatMember } from '../../../services/chatAdministrationApi';

type AssetRecipient = 'asset';

type DriverRecipient = 'driver';

export type MessageStatus = {
    delivered: boolean;
    read: boolean;
    recipient: AssetRecipient | DriverRecipient;
};

const SENDBIRD_ID_SEPARATOR = '_';

const isValidRecipient = (recipient?: string): recipient is AssetRecipient | DriverRecipient =>
    recipient === 'asset' || recipient === 'driver';

const notBackofficeUser = (userId: string) => !userId.startsWith('backoffice');

export const senderTypeFrom = (userId: string): ChatMember['type'] =>
    userId.split(SENDBIRD_ID_SEPARATOR).at(0) as ChatMember['type'];

export const defaultMessageStatus = (chatId: string): MessageStatus => {
    const recipient = chatId.split('_').at(0) as DriverRecipient | AssetRecipient;
    return {
        delivered: false,
        read: false,
        recipient,
    };
};

export const resolveMessageStatus = (deliveryStatuses: string[], readReceipts: string[]): MessageStatus | undefined => {
    const deliveredTo = deliveryStatuses.filter(notBackofficeUser).at(0);
    const readBy = readReceipts.find(userId => userId === deliveredTo);
    const recipient = deliveredTo?.split(SENDBIRD_ID_SEPARATOR).at(0);

    if (!isValidRecipient(recipient)) {
        return;
    }

    return {
        delivered: !!deliveredTo,
        read: !!readBy,
        recipient: recipient,
    };
};
