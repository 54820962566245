import { isAfter, isBefore, subHours } from 'date-fns';
import type { Reaction as SendbirdReaction, Sender, UserMessage } from '@sendbird/chat/message';

import type { DeliveryStatus, GroupChannel, Member } from '@sendbird/chat/groupChannel';

import type { Channel, Message, Reaction, Receipt } from '../../features/chat/chatSlice';

export type SimplifiedSender = Pick<Sender, 'userId'>;

export type SimplifiedUserMessage = Pick<
    UserMessage,
    'channelUrl' | 'messageId' | 'messageType' | 'createdAt' | 'translations'
> & {
    message?: string;
    sender?: SimplifiedSender;
};

export type SimplifiedMember = Pick<Member, 'userId'>;

export type SimplifiedDeliveryStatus = Pick<DeliveryStatus, 'deliveryAt'> & {
    member: SimplifiedMember;
};

export type SimplifiedGroupChannel = Pick<GroupChannel, 'url' | 'channelType'> & {
    unreadMessageCount?: number;
    lastMessage?: SimplifiedUserMessage | null;
};

export const toChannel = (channel: SimplifiedGroupChannel): Channel => ({
    channelId: channel.url,
    unreadMessageCount:
        (channel.lastMessage &&
            isAfter(channel.lastMessage.createdAt, subHours(new Date(), 72)) &&
            channel.unreadMessageCount) ||
        0,
    lastMessage:
        channel.lastMessage && isAfter(channel.lastMessage.createdAt, subHours(new Date(), 72))
            ? toMessage(channel.lastMessage)
            : undefined,
});

export const toMessage = (userMessage: SimplifiedUserMessage): Message => ({
    channelId: userMessage.channelUrl,
    id: userMessage.messageId,
    type: userMessage.messageType,
    text: userMessage.message || '',
    userId: userMessage.sender?.userId || 'admin',
    createdAt: userMessage.createdAt,
    translations: userMessage.translations as Record<string, string>,
});

export const toReceipt = (messageId: number, userIds: string[]): Receipt => ({
    messageId,
    userIds,
});

export const toDeliveryReceipt = (message: SimplifiedUserMessage, statuses: SimplifiedDeliveryStatus[]) =>
    toReceipt(
        message.messageId,
        statuses.filter(status => !isBefore(status.deliveryAt, message.createdAt)).map(status => status.member.userId)
    );

export const toReadReceipt = (message: SimplifiedUserMessage, members: SimplifiedMember[]): Receipt =>
    toReceipt(
        message.messageId,
        members.map(member => member.userId)
    );

export const toSliceReaction = (sendbirdReaction: SendbirdReaction): Reaction => {
    /**
     * Only driver or asset can add / delete reactions. There is always only 1 driver or asset in a chat.
     */
    const reactionOwner: string = sendbirdReaction.userIds.filter(userId => !userId.startsWith('backoffice'))[0];
    return {
        user: {
            userId: reactionOwner,
            nickname: reactionOwner,
        },
        value: sendbirdReaction.key,
    };
};
