import type { ReactNode } from 'react';
import CustomState from '@rio-cloud/rio-uikit/CustomState';
import { FormattedMessage } from 'react-intl';

import { type ChatErrorType, ChatErrorTypes } from './chatSlice';
import { gaPush, getTrackingAttributes, TRACKING_CATEGORIES } from '../../configuration/setup/googleAnalytics';

type ChatLoadingErrorProps = {
    errorType: ChatErrorType;
    errorText?: string;
};

export const ChatLoadingError = ({ errorType, errorText }: ChatLoadingErrorProps) => {
    let message: string | ReactNode;
    let showReloadButton = false;

    switch (errorType) {
        case ChatErrorTypes.CONNECTION_NOT_ESTABLISHED: {
            message = <FormattedMessage id='intl-msg:chat.error.connectionNotEstablished' />;
            showReloadButton = true;
            break;
        }
        case ChatErrorTypes.NOT_MEMBER_OF_CHAT_GROUP: {
            message = <FormattedMessage id='intl-msg:chat.error.notMemberOfGroup' />;
            break;
        }
        case ChatErrorTypes.CHAT_NOT_FOUND: {
            message = <FormattedMessage id='intl-msg:chat.error.chatNotFound' />;
            break;
        }
        default: {
            message = errorText || <FormattedMessage id='intl-msg:chat.error.unkownError' />;
            break;
        }
    }

    const handleReload = () => {
        gaPush({
            category: TRACKING_CATEGORIES.CHAT,
            action: 'Reload chat',
            label: 'Clicked on reload button to try again after chat error',
        });
        window.location.reload();
    };

    return (
        <div
            className='padding-15'
            {...getTrackingAttributes({
                trigger: 'visibility',
                category: TRACKING_CATEGORIES.CHAT,
                action: 'Show error',
                label: 'Show chat error',
            })}
        >
            <CustomState
                outerClassName='border-none margin-top-20'
                headline={<FormattedMessage id='intl-msg:chat.error.chatUnavailable' />}
                message={message}
                icon='rioglyph rioglyph-comment'
                buttons={
                    showReloadButton
                        ? [
                              {
                                  text: (
                                      <>
                                          <span className='rioglyph rioglyph-refresh' />
                                          <span>
                                              <FormattedMessage id='intl-msg:chat.label.tryAgain' />
                                          </span>
                                      </>
                                  ),
                                  onClick: handleReload,
                              },
                          ]
                        : undefined
                }
            />
        </div>
    );
};
