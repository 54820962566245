import { FormattedMessage } from 'react-intl';
import AppNavigationBar from '@rio-cloud/rio-uikit/AppNavigationBar';

import { useAppContext } from '../../layout/AppContext';

type NewChatHeaderProps = {
    count?: number;
};

export const NewChatHeader = ({ count = 0 }: NewChatHeaderProps) => {
    const { navigateToDefault } = useAppContext();

    const handleNavigateBack = () => {
        navigateToDefault('from new chat page');
    };

    return (
        <AppNavigationBar
            title={
                <>
                    <FormattedMessage id='intl-msg:chat.new.label.availableChats' /> ({count})
                </>
            }
            className='border border-bottom-only position-fixed top-0 bg-white z-index-max height-50'
            onNavigateBack={handleNavigateBack}
        />
    );
};
