import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import usePrevious from '@rio-cloud/rio-uikit/usePrevious';
import Fade, { type FadeAnimationStyle } from '@rio-cloud/rio-uikit/Fade';
import useOnScreen from '@rio-cloud/rio-uikit/hooks/useOnScreen';

import { DEFAULT_LOCALE } from '../configuration/lang/lang';
import { useAppSelector } from '../configuration/setup/hooks';
import { getDisplayMessages, getLocale } from '../configuration/lang/langSlice';
import DefaultRedirect from '../routes/DefaultRedirect';
import RouteUpdater from '../routes/RouteUpdater';
import { AppContext } from './AppContext';
import { MessageHandler } from '../features/widgets/MessageHandler';
import { SendbirdProvider } from '../sendbird/useSendbirdApi';
import { useChatConnection } from '../sendbird/hooks/useChatConnection';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { BroadcastViewPage } from '../pages/BroadcastViewPage';
import { ChatListViewPage } from '../pages/ChatListViewPage';
import { FeedbackPage } from '../pages/FeedbackPage';
import {
    BROADCAST_ROUTE,
    CHAT_DETAILS_ROUTE,
    DEFAULT_ROUTE,
    FEEDBACK_ROUTE,
    HELP_ROUTE,
    NEW_CHAT_ROUTE,
} from '../routes/Router';
import { MessageViewPage } from '../pages/MessageViewPage';
import { useNavigationDeepLink } from '../hooks/useNavigationDeepLink';
import { useDeepLink } from '../hooks/useDeepLink';
import { NewChatViewPage } from '../pages/NewChatViewPage';
import { channelSelected, chatVisibilityChanged } from '../features/chat/chatSlice';
import { gaPush, TRACKING_CATEGORIES } from '../configuration/setup/googleAnalytics';
import { HelpPage } from '../pages/HelpPage';

const withSource = (label: string, source: string) => `${label}${source ? ' ' : ''}${source}`;

const AppLayout = () => {
    const dispatch = useDispatch();
    const userLocale = useAppSelector(getLocale);
    const displayMessages = useAppSelector(getDisplayMessages);

    const chatConnection = useChatConnection();

    const sidebarRef = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();

    const [animationStyle, setAnimationStyle] = useState<FadeAnimationStyle>('page');
    const [currentLocation, setCurrentLocation] = useState<string>(DEFAULT_ROUTE);

    const { pathname } = useLocation();
    const previousLocation = usePrevious(pathname);
    const hasDifferentRoute = previousLocation !== pathname;

    useNavigationDeepLink((newLocation: string) => setCurrentLocation(newLocation));

    useDeepLink();

    const appRef = useRef<HTMLDivElement>(null);
    const onScreen = useOnScreen(appRef);
    useEffect(() => {
        dispatch(chatVisibilityChanged(onScreen));
    }, [onScreen]);

    if (!(displayMessages && userLocale)) {
        return null;
    }

    const handleNavigateToDefault = (source = '') => {
        gaPush({
            category: TRACKING_CATEGORIES.NAVIGATION,
            action: 'Navigate to default',
            label: withSource('Opened chat list page', source),
        });

        dispatch(channelSelected(undefined));
        navigate(DEFAULT_ROUTE);
        setCurrentLocation(DEFAULT_ROUTE);
        setAnimationStyle('pageBack');
    };

    const handleNavigateToNewChat = (source = '') => {
        gaPush({
            category: TRACKING_CATEGORIES.NAVIGATION,
            action: 'Navigate to new chat',
            label: withSource('Opened recipients page for new chat', source),
        });

        navigate(NEW_CHAT_ROUTE);
        setCurrentLocation(NEW_CHAT_ROUTE);
        setAnimationStyle('page');
    };

    const handleNavigateToBroadcast = (source = '') => {
        gaPush({
            category: TRACKING_CATEGORIES.NAVIGATION,
            action: 'Navigate to broadcast',
            label: withSource('Opened broadcast page', source),
        });

        navigate(BROADCAST_ROUTE);
        setCurrentLocation(BROADCAST_ROUTE);
        setAnimationStyle('page');
    };

    const handleNavigateToChat = (channelId: string, source = '') => {
        gaPush({
            category: TRACKING_CATEGORIES.NAVIGATION,
            action: 'Navigate to chat',
            label: withSource('Opened chat details page', source),
        });

        navigate(`${CHAT_DETAILS_ROUTE}?channelId=${channelId}`);
        setCurrentLocation(CHAT_DETAILS_ROUTE);
        setAnimationStyle('page');
    };

    const handleNavigateToFeedback = (source = '') => {
        gaPush({
            category: TRACKING_CATEGORIES.NAVIGATION,
            action: 'Navigate to feedback',
            label: withSource('Opened feedback page', source),
        });

        navigate(FEEDBACK_ROUTE);
        setCurrentLocation(FEEDBACK_ROUTE);
        setAnimationStyle('page');
    };

    const handleNavigateToHelp = (source = '') => {
        gaPush({
            category: TRACKING_CATEGORIES.NAVIGATION,
            action: 'Navigate to help',
            label: withSource('Opened help page', source),
        });

        navigate(HELP_ROUTE);
        setCurrentLocation(HELP_ROUTE);
        setAnimationStyle('page');
    };

    return (
        <ErrorBoundary>
            <IntlProvider
                defaultLocale={DEFAULT_LOCALE}
                key={userLocale}
                locale={userLocale}
                messages={displayMessages}
            >
                <AppContext.Provider
                    value={{
                        sidebarRef,
                        navigateToDefault: handleNavigateToDefault,
                        navigateToNewChat: handleNavigateToNewChat,
                        navigateToBroadcast: handleNavigateToBroadcast,
                        navigateToChat: handleNavigateToChat,
                        navigateToFeedback: handleNavigateToFeedback,
                        navigateToHelp: handleNavigateToHelp,
                    }}
                >
                    <ApplicationLayout
                        ref={appRef}
                        className='bg-white max-width-800 margin-auto overflow-hidden height-100vh'
                        data-testid='app-layout'
                    >
                        <SendbirdProvider value={chatConnection}>
                            <Fade
                                initial={false}
                                exitBeforeEnter
                                animationStyle={hasDifferentRoute ? animationStyle : 'fade'}
                                duration={0.25}
                                motionProps={{ transition: { duration: 0.25, type: 'spring' } }}
                            >
                                {currentLocation === DEFAULT_ROUTE && <ChatListViewPage key='chatListViewPage' />}
                                {currentLocation === NEW_CHAT_ROUTE && <NewChatViewPage key='newChatViewPage' />}
                                {currentLocation === BROADCAST_ROUTE && <BroadcastViewPage key='broadcastViewPage' />}
                                {currentLocation === CHAT_DETAILS_ROUTE && <MessageViewPage key='messageViewPage' />}
                                {currentLocation === FEEDBACK_ROUTE && <FeedbackPage key='feedbackPage' />}
                                {currentLocation === HELP_ROUTE && <HelpPage key='helpPage' />}
                                <div />
                            </Fade>
                            <RouteUpdater />
                            <DefaultRedirect />
                            <MessageHandler />
                        </SendbirdProvider>
                    </ApplicationLayout>
                </AppContext.Provider>
            </IntlProvider>
        </ErrorBoundary>
    );
};

export default AppLayout;
