import type { ReactNode } from 'react';
import { FormattedTime } from 'react-intl';
import Fade from '@rio-cloud/rio-uikit/Fade';

import { ReadStatus } from './ReadStatus';
import { useMessageStatus, useUserId } from './chatSlice';
import { ChatMemberItem } from './ChatMemberItem';
import { MessageReaction } from './MessageReaction';
import { useGetChatMemberQuery } from '../../services/chatAdministrationApi';
import { defaultMessageStatus, senderTypeFrom } from './messageStatus/messageStatus';
import { MessageTranslation } from './MessageTranslation';
import { useLocale } from '../../configuration/lang/langSlice';
import { useTranslations } from '../../hooks/useTranslations';

const isSingleEmoji = (text: string) => {
    const emojiRegex = /^[\u231A-\uDFFF]+$/;
    return emojiRegex.test(text.trim());
};

type MessageItemProps = {
    chatId: string;
    messageId: number;
    messageText: string;
    showSender: boolean;
    senderName: string;
    sendAt: number;
    footer?: ReactNode;
    hideReadStatus?: boolean;
    translations?: Record<string, string>;
};

export const MessageItem = (props: MessageItemProps) => {
    const {
        messageText,
        translations = {},
        showSender,
        senderName,
        sendAt,
        chatId,
        messageId,
        footer,
        hideReadStatus = false,
    } = props;

    const currentUserId = useUserId();
    const messageStatus = useMessageStatus(chatId, messageId) ?? defaultMessageStatus(chatId);
    const { data: senderChatMember } = useGetChatMemberQuery({ chatId, userId: senderName });
    const sender = senderChatMember?.type || senderTypeFrom(senderName);

    const locale = useLocale() || 'en';

    const {
        showOriginalMessage,
        showOriginalMessageButton,
        isLoading,
        error,
        handleFetchTranslation,
        handleRetryFetchTranslation,
        handleShowOriginalMessage,
        translation,
    } = useTranslations({ chatId, messageId, locale, translations });

    const isOwnMessage = senderName === currentUserId;

    const isEmoji = isSingleEmoji(messageText);

    const showTranslationButton = !isEmoji && !isOwnMessage;

    // if user is himself, change styling accordingly
    const wrapperClassName = isOwnMessage ? 'align-items-end' : 'align-items-start';
    const messageClassName = isOwnMessage
        ? 'bg-highlight-lightest text-right rounded-top-left rounded-bottom'
        : 'bg-lightest text-left rounded-top-right rounded-bottom';
    const sendAtClassName = isOwnMessage ? 'justify-content-end' : 'justify-content-start';

    return (
        <div className={`MessageItem display-flex flex-column word-break-all ${wrapperClassName}`}>
            {showSender && !isOwnMessage && <ChatMemberItem chatId={chatId} userId={senderName} />}
            <div className='max-width-90pct'>
                <div className={`${messageClassName} padding-10`}>
                    <div className='text-color-darker line-height-125-rel'>
                        {isEmoji && <div className='text-size-h2'>{messageText}</div>}
                        {!isEmoji && (
                            <Fade initial={false} exitBeforeEnter>
                                {showOriginalMessage && <span key='orgMessage'>{messageText}</span>}
                                {!showOriginalMessage && (
                                    <span key='translatedMessage'>{translation || messageText}</span>
                                )}
                            </Fade>
                        )}
                    </div>
                    <div
                        className={`text-size-11 margin-top-10 display-flex align-items-end gap-2 text-color-dark user-select-none ${sendAtClassName}`}
                    >
                        {footer}
                        <span className='display-flex gap-5 align-items-center'>
                            <FormattedTime value={sendAt} hour='numeric' minute='numeric' />
                            {!hideReadStatus && <ReadStatus sender={sender} messageStatus={messageStatus} />}
                        </span>
                        {showTranslationButton && (
                            <MessageTranslation
                                isLoading={isLoading}
                                isError={!!error}
                                showOriginalButton={showOriginalMessageButton}
                                onShowOriginalMessage={handleShowOriginalMessage}
                                onFetchTranslation={handleFetchTranslation}
                                onRetryFetchTranslation={handleRetryFetchTranslation}
                            />
                        )}
                    </div>
                    <MessageReaction channelId={chatId} messageId={messageId} />
                </div>
            </div>
        </div>
    );
};
