import { FormattedMessage } from 'react-intl';
import EmptyState from '@rio-cloud/rio-uikit/EmptyState';

export const EmptyChatList = () => (
    <div>
        <div className='position-absolute top-10 right-15 text-size-20 animated-1s infinite ease-in-out loop slide-out-down'>
            <span className='rioglyph rioglyph-arrow-up text-size-200pct text-color-lighter opacity-80' />
        </div>
        <EmptyState
            outerClassName='border-none'
            headline={<FormattedMessage id='intl-msg:chat.list.label.emptyChatList' />}
            message={<FormattedMessage id='intl-msg:chat.list.label.emptyChatList.description' />}
            icon='rioglyph-chat'
        />
    </div>
);
