import { PureComponent, type ErrorInfo, type ReactNode } from 'react';
import * as Sentry from '@sentry/browser';
import ErrorState from '@rio-cloud/rio-uikit/ErrorState';

interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    error?: Error;
}

export class ErrorBoundary extends PureComponent<ErrorBoundaryProps, ErrorBoundaryState> {
    state: Readonly<ErrorBoundaryState> = {
        error: undefined,
    };

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        Sentry.withScope(scope => {
            scope.setExtra('componentStack', errorInfo.componentStack);
            Sentry.captureException(error);
        });
        this.setState({ error });
    }

    render() {
        const { error } = this.state;
        const { children } = this.props;
        if (error) {
            <div className={'display-flex justify-content-center margin-top-15'}>
                <ErrorState
                    headline={'Oops, something went wrong'}
                    message={
                        <div>
                            {'If this error persists, please contact the RIO Support Hotline:'}
                            <br />
                            <span className={'text-size-large margin-top-10'}>{'00800 / 22550746'}</span>
                        </div>
                    }
                />
            </div>;
        }
        return children;
    }
}
