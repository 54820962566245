declare global {
    interface Window {
        // biome-ignore lint/suspicious/noExplicitAny: I guess we need to define a type
        dataLayer?: any[];
    }
}

export interface GAEvent {
    category: string;
    action: string;
    label: string;
}

export const gaPush = (gaEvent: GAEvent) => {
    if (window.dataLayer) {
        window.dataLayer.push({
            event: 'ga_event',
            eventCategory: gaEvent.category,
            eventAction: gaEvent.action,
            eventLabel: gaEvent.label,
        });
    }
};

export interface TrackingValues {
    trigger: 'click' | 'visibility';
    category: string;
    action: string;
    label: string;
    value?: string;
}

export interface TrackingAttributes {
    'data-track-ga-event-trigger': string;
    'data-track-ga-event-category': string;
    'data-track-ga-event-action': string;
    'data-track-ga-event-label'?: string;
    'data-track-ga-event-value'?: string;
}

export const getTrackingAttributes = (param: TrackingValues) => {
    const { trigger, category, action, label, value } = param;

    const attributes: TrackingAttributes = {
        'data-track-ga-event-trigger': trigger,
        'data-track-ga-event-category': category,
        'data-track-ga-event-action': action || trigger,
    };

    if (label) {
        attributes['data-track-ga-event-label'] = label;
    }

    if (value) {
        attributes['data-track-ga-event-value'] = value;
    }

    return attributes;
};

// categories as defined for all services on:
// https://collaboration.msi.audi.com/confluence/display/MAN/Overarching+Topics
export const TRACKING_CATEGORIES = {
    CHAT: 'OrderCommunicationChat, chat',
    BROADCAST: 'OrderCommunicationChat, broadcast',
    NEW_CHAT: 'OrderCommunicationChat, newChat',
    TRANSLATIONS: 'OrderCommunicationChat, translations',
    NAVIGATION: 'OrderCommunicationChat, navigations',
    INT_LINK: 'OrderCommunicationChat, internalLinks',
    FEEDBACK: 'OrderCommunicationChat, feedback',
};
