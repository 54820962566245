import { ChatMemberName } from '../../components/ChatMemberName';

type ChatMemberItemProps = {
    chatId: string;
    userId: string;
};

export const ChatMemberItem = (props: ChatMemberItemProps) => {
    const { chatId, userId } = props;
    return (
        <div className='text-color-darkest text-size-14 text-medium'>
            <ChatMemberName chatId={chatId} userId={userId} showIcon />
        </div>
    );
};
