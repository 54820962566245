import { FormattedMessage, FormattedPlural } from 'react-intl';

import { NewChatButton } from '../chatList/NewChatButton';
import { useGroupChannels, useTotalUnreadMessageCount } from '../chat/chatSlice';

type ChatListHeaderProps = {
    openChatsCount?: number;
};

export const ChatListHeader = (props: ChatListHeaderProps) => {
    const { openChatsCount = 0 } = props;

    const groupChannels = useGroupChannels();
    const totalUnreadMessageCount = useTotalUnreadMessageCount();

    return (
        <div
            className='ChatListHeader padding-x-15 padding-y-15 bg-lightest display-flex align-items-center border border-bottom-only'
            data-testid='open-chats-header'
        >
            <div className='flex-1-1'>
                <div className='text-size-18 text-medium margin-0 text-color-darker'>
                    <FormattedMessage
                        id='intl-msg:chat.list.label.openChats'
                        values={{
                            count: openChatsCount,
                        }}
                    />
                </div>
                <div className='text-color-darker text-size-12'>
                    {totalUnreadMessageCount === 0 && (
                        <FormattedMessage id='intl-msg:chat.list.label.newMessages.zero' />
                    )}
                    {totalUnreadMessageCount > 0 && (
                        <FormattedPlural
                            one={
                                <FormattedMessage
                                    id='intl-msg:chat.list.label.newMessages.one'
                                    values={{ count: totalUnreadMessageCount }}
                                />
                            }
                            other={
                                <FormattedMessage
                                    id='intl-msg:chat.list.label.newMessages.other'
                                    values={{ count: totalUnreadMessageCount }}
                                />
                            }
                            value={totalUnreadMessageCount}
                        />
                    )}
                </div>
            </div>
            <NewChatButton />
        </div>
    );
};
