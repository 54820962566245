import type { PropsWithChildren } from 'react';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';

import BlueDoubleCheckmarkIcon from '../../assets/read.svg?react';
import GreyDoubleCheckmarkIcon from '../../assets/received.svg?react';
import GreySingleCheckmarkIcon from '../../assets/sent.svg?react';
import { FormattedMessage } from 'react-intl';
import type { MessageStatus } from './messageStatus/messageStatus';
import { gaPush, TRACKING_CATEGORIES } from '../../configuration/setup/googleAnalytics';

type ReadStatusTooltipProps = {
    recipient: MessageStatus['recipient'];
};

export const ReadStatusTooltip = ({ recipient, children }: PropsWithChildren<ReadStatusTooltipProps>) => {
    const tooltipI18nKey = `intl-msg:chat.${recipient}ReadStatusTooltip`;

    const handleToggleTooltip = (isOpen: boolean) => {
        if (isOpen) {
            gaPush({
                category: TRACKING_CATEGORIES.CHAT,
                action: 'Show read status tooltip',
                label: `Hovered over read status tooltip for ${recipient}`,
            });
        }
    };

    return (
        <OverlayTrigger
            delay={{ show: 800, hide: 0 }}
            placement='bottom-end'
            onToggle={handleToggleTooltip}
            overlay={
                <Tooltip className='max-width-200'>
                    <div className='text-size-11 text-left'>
                        <FormattedMessage
                            id={tooltipI18nKey}
                            values={{
                                h1: chunk => <div className='margin-bottom-5'>{chunk}</div>,
                                h2: chunk => (
                                    <div className='display-flex'>
                                        <div className='width-20'>
                                            <GreySingleCheckmarkIcon />
                                        </div>
                                        <div>{chunk}</div>
                                    </div>
                                ),
                                h3: chunk => (
                                    <div className='display-flex'>
                                        <div className='width-20'>
                                            <GreyDoubleCheckmarkIcon />
                                        </div>
                                        <div>{chunk}</div>
                                    </div>
                                ),
                                h4: chunk => (
                                    <div className='display-flex'>
                                        <div className='width-20'>
                                            <BlueDoubleCheckmarkIcon />
                                        </div>
                                        <div>{chunk}</div>
                                    </div>
                                ),
                            }}
                        />
                    </div>
                </Tooltip>
            }
        >
            {children}
        </OverlayTrigger>
    );
};
