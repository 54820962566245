import type { ChatMemberType } from '../services/chatAdministrationApi';

type ChatMemberInlineIconProps = {
    memberType: ChatMemberType;
};

export const ChatMemberInlineIcon = ({ memberType }: ChatMemberInlineIconProps) => {
    switch (memberType) {
        case 'asset':
            return <span data-testid='chat-member-icon-asset' className='padding-top-3 rioglyph rioglyph-truck' />;
        case 'backoffice':
            return (
                <span
                    data-testid='chat-member-icon-backoffice'
                    className='padding-top-3 rioglyph rioglyph-businessman'
                />
            );
        case 'driver':
            return <span data-testid='chat-member-icon-driver' className='padding-top-3 rioglyph rioglyph-driver' />;
    }
};
