import { useDispatch } from 'react-redux';
import { SendbirdErrorCode } from '@sendbird/chat';

import { type BackendError, chatError, ChatErrorTypes, type SendbirdError } from '../../features/chat/chatSlice';

export const useChatErrorHandlers = () => {
    const dispatch = useDispatch();

    const handleError = (error: unknown) => {
        if (isSendbirdError(error)) {
            handleSendbirdError(error);
        } else if (isBackendError(error)) {
            handleBackendError(error);
        } else {
            dispatch(
                chatError({
                    errorType: ChatErrorTypes.CONNECTION_NOT_ESTABLISHED,
                    errorText: JSON.stringify(error),
                })
            );
        }
    };

    const isSendbirdError = (error: unknown): error is SendbirdError => {
        return (error as SendbirdError).code !== undefined;
    };

    const isBackendError = (error: unknown): error is BackendError => {
        return (error as BackendError).status !== undefined;
    };

    const handleBackendError = (error: BackendError) => {
        switch (error.status) {
            case 403:
                dispatch(
                    chatError({
                        errorType: ChatErrorTypes.NOT_MEMBER_OF_CHAT_GROUP,
                    })
                );
                break;
            case 404:
                dispatch(
                    chatError({
                        errorType: ChatErrorTypes.CHAT_NOT_FOUND,
                    })
                );
                break;
            default:
                dispatch(
                    chatError({
                        errorType: ChatErrorTypes.CONNECTION_NOT_ESTABLISHED,
                    })
                );
        }
    };

    const handleSendbirdError = (error: SendbirdError) => {
        switch (error.code) {
            case SendbirdErrorCode.ACK_TIMEOUT:
                break;
            default:
                dispatch(
                    chatError({
                        errorType: ChatErrorTypes.CONNECTION_NOT_ESTABLISHED,
                        errorText: JSON.stringify(error),
                    })
                );
        }
    };

    return {
        handleError,
    };
};
