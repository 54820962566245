import { MessageItem } from './MessageItem';
import type { Message } from './chatSlice';

type MessageItemsProps = {
    messages: Message[];
};

const MessageHistory = ({ messages }: MessageItemsProps) => {
    return (
        <div className='display-flex flex-column gap-10'>
            {messages.map((message, index) => (
                <MessageItem
                    key={message.id}
                    chatId={message.channelId}
                    messageId={message.id}
                    showSender={index > 0 ? messages[index - 1].userId !== message.userId : true}
                    senderName={message.userId}
                    messageText={message.text}
                    sendAt={message.createdAt}
                    translations={message.translations}
                />
            ))}
        </div>
    );
};

export default MessageHistory;
