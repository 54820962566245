import EmptyState from '@rio-cloud/rio-uikit/EmptyState';
import { FormattedMessage } from 'react-intl';

export const EmptyChatState = () => {
    return (
        <EmptyState
            outerClassName='border-none'
            headline={<FormattedMessage id='intl-msg:chat.emptyState.title' />}
            message={<FormattedMessage id='intl-msg:chat.emptyState.description' />}
            icon='rioglyph-chat'
        />
    );
};
