import { useState } from 'react';
import { useUserProfile } from '../configuration/login/loginSlice';
import { useSendFeedbackMutation } from '../services/contactFormApi';
import { gaPush, TRACKING_CATEGORIES } from '../configuration/setup/googleAnalytics';

export const useFeedback = (featureRating: number | undefined) => {
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [sendFeedbackEmail] = useSendFeedbackMutation();

    const userProfile = useUserProfile();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        // Avoid page reload on submit
        event.preventDefault();

        // Get form data of all inputs
        const data = new FormData(event.target as HTMLFormElement);

        sendFeedback(data.get('feedbackNotes')?.toString() || '', featureRating);

        // used to show a thank you message
        setIsSubmitted(true);

        gaPush({
            category: TRACKING_CATEGORIES.FEEDBACK,
            label: 'Feedback submit',
            action: 'Clicked the submit button',
        });
    };

    const sendFeedback = (messageToSend: string, featureRating: number | undefined) => {
        const message = `[
            { "Chat rating": "${featureRating}" },
            { "Feedback message": "${messageToSend}" },
            { "Navigator userAgent": "${window.navigator.userAgent}" },
            { "HTML classes": "${document.querySelector('html')?.className}" },
            { "DevicePixelRatio": "${window.devicePixelRatio}" },
            { "Browser width": "${window.innerWidth}" },
            { "Browser height": "${window.innerHeight}" }
        ]`;

        console.debug(`Sending email with message \n${message}`);

        if (import.meta.env.PROD) {
            sendFeedbackEmail({ message, userProfile });
        }
    };

    return {
        isSubmitted,
        handleSubmit,
    };
};
