import { useState } from 'react';
import { FormattedMessage, FormattedPlural, useIntl } from 'react-intl';
import type { SelectOption } from '@rio-cloud/rio-uikit/Select';
import Multiselect from '@rio-cloud/rio-uikit/Multiselect';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import noop from 'lodash/fp/noop';
import isEmpty from 'lodash/fp/isEmpty';

type BroadcastRecipientsSelectProps = {
    options?: SelectOption[];
    onSelectRecipients?: (recipients: string[]) => void;
    className?: string;
};

export const BroadcastRecipientsSelect = (props: BroadcastRecipientsSelectProps) => {
    const { options = [], onSelectRecipients = noop, className = '' } = props;

    const intl = useIntl();

    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

    const totalRecipients = selectedOptions.length || options.length;

    const hasChatMembers = !isEmpty(options);

    return (
        <div className={className}>
            <label htmlFor='m1' className='display-flex flex-wrap justify-content-between'>
                <span>
                    <FormattedMessage id='intl-msg:chat.broadcast.label.recipientSelection' />
                </span>
                <span className='text-italic'>
                    <>
                        {totalRecipients}{' '}
                        <FormattedPlural
                            value={totalRecipients}
                            one={<FormattedMessage id='intl-msg:chat.broadcast.label.recipientsSelected.one' />}
                            other={<FormattedMessage id='intl-msg:chat.broadcast.label.recipientsSelected.other' />}
                        />
                    </>
                </span>
            </label>
            {/* TODO: add loading state to Multiselect component in UIKIT */}
            {!hasChatMembers && (
                <Multiselect
                    id='m1'
                    placeholder={intl.formatMessage({
                        id: 'intl-msg:chat.broadcast.placeholder.sendToAll',
                    })}
                    inputAddon='rioglyph rioglyph-truck'
                    options={[{ id: 'loading', label: <Spinner /> }]}
                />
            )}
            {hasChatMembers && (
                <Multiselect
                    id='m1'
                    placeholder={intl.formatMessage({
                        id: 'intl-msg:chat.broadcast.placeholder.sendToAll',
                    })}
                    options={options}
                    inputAddon='rioglyph rioglyph-truck'
                    useFilter
                    multiline
                    noItemMessage='No items found'
                    onChange={response => {
                        setSelectedOptions(response);
                        onSelectRecipients(response);
                    }}
                />
            )}
        </div>
    );
};
