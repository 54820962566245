import { FormattedMessage } from 'react-intl';
import EmptyState from '@rio-cloud/rio-uikit/EmptyState';

export const EmptyNewChatList = () => (
    <EmptyState
        condensed
        outerClassName='border-none'
        headline={<FormattedMessage id='intl-msg:chat.list.label.emptyNewChatList' />}
        message={<FormattedMessage id='intl-msg:chat.list.label.emptyNewChatList.description' />}
        icon='rioglyph-chat'
    />
);
