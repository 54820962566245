/// <reference types='vite-plugin-svgr/client' />

import { ReadStatusTooltip } from './ReadStatusTooltip';
import BlueDoubleCheckmarkIcon from '../../assets/read.svg?react';
import GreyDoubleCheckmarkIcon from '../../assets/received.svg?react';
import GreySingleCheckmarkIcon from '../../assets/sent.svg?react';
import type { ChatMember } from '../../services/chatAdministrationApi';
import type { MessageStatus } from './messageStatus/messageStatus';

type ReadStatusProps = {
    sender: ChatMember['type'];
    messageStatus: MessageStatus;
};

type IconStyles = {
    [key in 'asset' | 'driver']: string;
};

const iconStyles: IconStyles = {
    asset: 'rioglyph rioglyph-truck',
    driver: 'rioglyph rioglyph-driver',
};

export const ReadStatus = ({ sender, messageStatus }: ReadStatusProps) => {
    if (sender === 'asset' || sender === 'driver') {
        return;
    }

    const { delivered, read, recipient } = messageStatus;

    const icon = read ? (
        <BlueDoubleCheckmarkIcon data-testid='blue-double-checkmark-icon' />
    ) : delivered ? (
        <GreyDoubleCheckmarkIcon data-testid='grey-double-checkmark-icon' />
    ) : (
        <GreySingleCheckmarkIcon data-testid='single-checkmark-icon' />
    );

    return (
        <ReadStatusTooltip data-testid='read-status-tooltip' recipient={recipient}>
            <span className='scale-90 display-flex gap-2 align-items-center'>
                <span className={`${iconStyles[recipient]} text-size-12 ${read ? 'text-color-primary' : ''}`} />
                {icon}
            </span>
        </ReadStatusTooltip>
    );
};
