type FooterButtonProps = {
    onClick?: VoidFunction;
    iconName?: string;
    text?: string | JSX.Element;
};

export const FooterButton = (props: FooterButtonProps) => {
    const { iconName, text, onClick } = props;

    return (
        <div className='display-flex gap-5 text-size-12 hover-text-color-dark cursor-pointer' onClick={onClick}>
            <span className={`rioglyph ${iconName} line-height-16`} />
            <span>{text}</span>
        </div>
    );
};
