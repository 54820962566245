import { useEffect } from 'react';
import { useBeforeUnload } from 'react-router-dom';
import type { Dispatch } from '@reduxjs/toolkit';
import useEffectOnce from '@rio-cloud/rio-uikit/useEffectOnce';

import { useAppDispatch } from '../../configuration/setup/hooks';
import { useTotalUnreadMessageCount } from '../chat/chatSlice';
import useTabActive from '../../hooks/useTabActive';
import { useSendbirdApi } from '../../sendbird/useSendbirdApi';

export const PREFIX = 'EVENT';

// biome-ignore lint/suspicious/noExplicitAny: Until its clear how to type message
export const sendMessage = (message: any) => {
    const parentWindow = window.top;
    if (parentWindow) {
        parentWindow.postMessage(message, '*');
    }
};

export const receiveMessage = (event: MessageEvent, dispatch: Dispatch) => {
    const { type = '', payload } = event.data;

    if (!type.startsWith(PREFIX) || !dispatch) {
        return;
    }

    switch (type) {
        // case 'EVENT_SELECT_ASSET': {
        //     const { id } = payload;
        //     if (id) {
        //         dispatch(channelSelected(`asset_${id}`));
        //     }
        //     break;
        // }
        /* In case of chat in header is always being set to not visible

        case 'EVENT_SET_VISIBLE_WIDGETS': {
            const isVisible = payload.includes('ChatWidget');
            dispatch(chatVisibilityChanged(isVisible));
            break;
        }*/
        default:
            break;
    }
};

export const MessageHandler = () => {
    const dispatch = useAppDispatch();

    const isBrowserTabActive = useTabActive();
    const totalUnreadMessageCount = useTotalUnreadMessageCount();

    const { disconnect, reconnect } = useSendbirdApi();

    useBeforeUnload(event => {
        disconnect();
    });

    // Register PostMessage event listener which takes a callback function to be executed when the child
    // triggers a defined remote action
    useEffectOnce(() => {
        const listenerCallback = (event: MessageEvent) => receiveMessage(event, dispatch);
        window.addEventListener('message', listenerCallback, false);
        return () => window.removeEventListener('message', listenerCallback, false);
    });

    // Send notification to livemonitor and get back initial state data
    // like if there is a charging station selected or not
    useEffectOnce(() => {
        sendMessage({
            type: 'EVENT_WIDGET_LOADED',
        });
    });

    useEffect(() => {
        sendMessage({
            type: 'EVENT_WIDGET_CHAT_TOTAL_UNREAD_MESSAGE_COUNT_CHANGED',
            payload: totalUnreadMessageCount,
        });
    }, [totalUnreadMessageCount]);

    useEffect(() => {
        console.log('isBrowserTabActive', isBrowserTabActive);
        if (isBrowserTabActive) {
            reconnect();
        } else {
            disconnect();
        }
    }, [isBrowserTabActive]);

    return null;
};
