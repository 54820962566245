import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import type {
    GroupChannelCollection,
    GroupChannelCollectionEventHandler,
    GroupChannelEventContext,
} from '@sendbird/chat/groupChannel';
import type { BaseChannel } from '@sendbird/chat';

import { sendbird } from '../client/sendbird';
import { type SimplifiedGroupChannel, toChannel } from './mapper';
import {
    groupChannelsAdded,
    groupChannelsDeleted,
    groupChannelsUpdated,
    useIsInitialized,
} from '../../features/chat/chatSlice';

export const useChannelListHandler = () => {
    const dispatch = useDispatch();
    const isInitialized = useIsInitialized();

    const synchronizeChannels = async () => {
        const groupChannelCollection = sendbird.groupChannel.createGroupChannelCollection();
        while (groupChannelCollection.hasMore) {
            await groupChannelCollection.loadMore();
        }
        const groupChannels = groupChannelCollection.channels;
        const channels = groupChannels.map(groupChannel => toChannel(groupChannel as SimplifiedGroupChannel));
        dispatch(groupChannelsAdded(channels));
        registerChannelListHandlers(groupChannelCollection);
    };

    const registerChannelListHandlers = async (groupChannelCollection: GroupChannelCollection) => {
        const groupChannelCollectionEventHandler: GroupChannelCollectionEventHandler = {
            onChannelsAdded: (context: GroupChannelEventContext, groupChannels: BaseChannel[]) => {
                const channels = groupChannels.map(groupChannel => toChannel(groupChannel));
                dispatch(groupChannelsAdded(channels));
            },
            onChannelsUpdated: (context: GroupChannelEventContext, groupChannels: BaseChannel[]) => {
                const channels = groupChannels.map(groupChannel => toChannel(groupChannel));
                dispatch(groupChannelsUpdated(channels));
            },
            onChannelsDeleted: (context: GroupChannelEventContext, groupChannelUrls: string[]) => {
                dispatch(groupChannelsDeleted(groupChannelUrls));
            },
        };

        groupChannelCollection.setGroupChannelCollectionHandler(groupChannelCollectionEventHandler);
    };

    useEffect(() => {
        if (isInitialized) {
            synchronizeChannels();
        }
    }, [isInitialized]);
};
