import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ConnectionHandler } from '@sendbird/chat';

import { useRequestTokenMutation } from '../../services/chatAdministrationApi';
import { chatErrorResetted, chatInitialized, useActiveChatId, userIdFetched } from '../../features/chat/chatSlice';
import { sendbird } from '../client/sendbird';
import { useChatErrorHandlers } from './useChatErrorHandlers';
import { useChannelListHandler } from './useChannelListHandler';
import { useMessageEventHandler } from './useMessageEventHandler';
import type { ChatConnection } from '../types';

export const useChatConnection = (): ChatConnection => {
    const [requestToken] = useRequestTokenMutation();
    const [initializing, setInitializing] = useState(false);

    const dispatch = useDispatch();
    const activeChannelId = useActiveChatId();

    const { handleError } = useChatErrorHandlers();

    useChannelListHandler();
    useMessageEventHandler();

    useEffect(() => {
        initializeSendbird();
    }, []);

    const initializeSendbird = async () => {
        // Make sure we only initialize Sendbird once
        if (initializing) {
            return;
        }
        setInitializing(true);
        try {
            dispatch(chatErrorResetted());
            const result = await requestToken().unwrap();
            await sendbird.connect(result.userId, result.token);

            sendbird.addConnectionHandler(
                result.userId,
                new ConnectionHandler({
                    onReconnectSucceeded: () => {
                        dispatch(chatErrorResetted());
                    },
                })
            );

            dispatch(userIdFetched(result.userId));
            dispatch(chatInitialized());
        } catch (error) {
            handleError(error);
        } finally {
            setInitializing(false);
        }
    };

    const sendMessage = async (message: string) => {
        try {
            const channel = activeChannelId ? await sendbird.groupChannel.getChannel(activeChannelId) : null;
            channel?.sendUserMessage({
                message,
            });
        } catch (error) {
            handleError(error);
        }
    };

    const markMessagesAsRead = async () => {
        try {
            const channel = activeChannelId ? await sendbird.groupChannel.getChannel(activeChannelId) : null;
            await channel?.markAsRead();
        } catch (error) {
            // Error handling disabled on purpose.Marking messages as read is a fire and forget operation.
        }
    };

    const disconnect = async (): Promise<void> => {
        console.log('Disconnection chat connection');
        await sendbird.disconnectWebSocket();
    };

    const reconnect = async (): Promise<void> => {
        console.log('Reconnection chat connection');
        sendbird.reconnect();
    };

    const resetChatErrors = () => {
        dispatch(chatErrorResetted());
    };

    return {
        markMessagesAsRead,
        sendMessage,
        disconnect,
        reconnect,
        resetChatErrors,
    };
};
