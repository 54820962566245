import { GroupChannelModule, type SendbirdGroupChat } from '@sendbird/chat/groupChannel';
import SendbirdChat from '@sendbird/chat';

const env = import.meta.env;

export const sendbird: SendbirdGroupChat = SendbirdChat.init({
    appId: env.VITE_SENDBIRD_APP_ID,
    localCacheEnabled: true,
    modules: [new GroupChannelModule()],
}) as SendbirdGroupChat;
