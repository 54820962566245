type DriverNameFormatProps = {
    firstName?: string;
    lastName?: string;
};

export const DriverNameFormat = (props: DriverNameFormatProps) => {
    const { firstName = '', lastName = '' } = props;

    return (
        <span className='display-flex gap-3'>
            <span className='text-thin'>{firstName}</span>
            <span>{lastName}</span>
        </span>
    );
};
