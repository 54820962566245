import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ButtonDropdown from '@rio-cloud/rio-uikit/ButtonDropdown';

import { useAppContext } from '../../layout/AppContext';
import { gaPush, TRACKING_CATEGORIES } from '../../configuration/setup/googleAnalytics';

type NewChatButtonProps = NonNullable<unknown>;

export const NewChatButton = (props: NewChatButtonProps) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const { navigateToNewChat, navigateToBroadcast } = useAppContext();

    const buttonDropdownItems = [
        {
            value: (
                <div className='display-flex gap-5'>
                    <div>
                        <span className='rioglyph rioglyph-comment text-color-darker' aria-hidden='true' />
                    </div>
                    <div>
                        <div className='text-medium'>
                            <FormattedMessage id='intl-msg:chat.list.label.newChat' />
                        </div>
                        <div className='text-size-12 text-color-dark margin-top-3'>
                            <FormattedMessage id='intl-msg:chat.list.label.newChat.description' />
                        </div>
                    </div>
                </div>
            ),
            onSelect: () => navigateToNewChat('from new chat button'),
        },
        {
            value: (
                <div className='display-flex gap-5'>
                    <div>
                        <span className='rioglyph rioglyph-megaphone text-color-darker' aria-hidden='true' />
                    </div>
                    <div>
                        <div className='text-medium'>
                            <FormattedMessage id='intl-msg:chat.list.label.announcement' />
                        </div>
                        <div className='text-size-12 text-color-dark margin-top-3'>
                            <FormattedMessage id='intl-msg:chat.list.label.announcement.description' />
                        </div>
                    </div>
                </div>
            ),
            onSelect: () => navigateToBroadcast('from new chat button'),
        },
    ];

    const handleOpenDropdown = () => {
        gaPush({
            category: TRACKING_CATEGORIES.NEW_CHAT,
            action: 'New chat button',
            label: 'Clicked on new chat button to open dropdown',
        });
        setIsDropdownOpen(true);
    };

    const handleCloseDropdown = () => {
        gaPush({
            category: TRACKING_CATEGORIES.NEW_CHAT,
            action: 'New chat button',
            label: 'Closed new chat button dropdown via click or click outside',
        });
        setIsDropdownOpen(false);
    };

    return (
        <ButtonDropdown
            bsStyle='primary'
            bsSize='md'
            title={
                <span
                    className={`rioglyph rioglyph-plus transition-all transition-duration-01 transition-ease-in-out ${
                        isDropdownOpen ? 'rotate-45' : ''
                    }`}
                />
            }
            items={buttonDropdownItems}
            onOpen={handleOpenDropdown}
            onClose={handleCloseDropdown}
            dropdownClassName='margin-top-10 min-width-300'
            pullRight
            iconOnly
        />
    );
};
