import { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { getTime } from 'date-fns';
import type { SelectOption } from '@rio-cloud/rio-uikit/Select';
import CustomState from '@rio-cloud/rio-uikit/CustomState';
import AppNavigationBar from '@rio-cloud/rio-uikit/AppNavigationBar';
import isEmpty from 'lodash/fp/isEmpty';

import { useGetChatsQuery, useSendBroadcastMessageMutation } from '../services/chatAdministrationApi';
import { MessageInput } from '../features/messageInput/MessageInput';
import { useUserId } from '../features/chat/chatSlice';
import { useAppContext } from '../layout/AppContext';
import { BroadcastRecipientsSelect } from '../features/broadcast/BroadcastRecipientsSelect';
import { BroadcastConfirmation } from '../features/broadcast/BroadcastConfirmation';
import { type BroadcastMessage, BroadcastMessages } from '../features/broadcast/BroadcastMessages';
import { gaPush, TRACKING_CATEGORIES } from '../configuration/setup/googleAnalytics';

export const BroadcastViewPage = () => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [pendingMessage, setPendingMessage] = useState('');

    const { navigateToDefault } = useAppContext();

    const userId = useUserId();

    const [messages, setMessages] = useState<BroadcastMessage[]>([]);
    const [selectedAssets, setSelectedAssets] = useState<string[]>([]);

    const { data: chatMembers = [] } = useGetChatsQuery();
    const [sendBroadcastMessage] = useSendBroadcastMessageMutation();

    const scrollToRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!isEmpty(messages)) {
            scrollToRef.current?.scrollIntoView({
                behavior: 'smooth',
            });
        }
    }, [messages]);

    const chatMemberSelectOptions: SelectOption[] = useMemo(() => {
        return chatMembers
            .filter(member => member.type === 'asset' || member.type === 'driver')
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(member => ({
                id: member.id,
                label: member.name,
            }));
    }, [chatMembers]);

    const handleNavigateBack = () => {
        navigateToDefault('from broadcast page');
    };

    const handleSendAttempt = (messageText: string) => {
        gaPush({
            category: TRACKING_CATEGORIES.BROADCAST,
            action: 'Send attempt',
            label: 'Clicked on send message button',
        });

        setShowConfirmation(true);
        setPendingMessage(messageText);
    };

    const handleSelectRecipients = (response: string[]) => {
        gaPush({
            category: TRACKING_CATEGORIES.BROADCAST,
            action: 'Select recipients',
            label: 'Clicked on entries of recipients select dropdown',
        });
        setSelectedAssets(response);
    };

    const getAllChatGroups = () => chatMemberSelectOptions.map(option => option.id);

    const handleConfirmSend = () => {
        gaPush({
            category: TRACKING_CATEGORIES.BROADCAST,
            action: 'Confirm send',
            label: 'Clicked on confirm button to send message',
        });

        setShowConfirmation(false);

        if (!userId) {
            return;
        }

        sendBroadcastMessage({
            message: pendingMessage,
            groups: selectedAssets.length ? selectedAssets : getAllChatGroups(),
        });

        const message: BroadcastMessage = {
            id: messages.length,
            channelId: 'broadcast',
            type: 'admin',
            createdAt: getTime(new Date()),
            text: pendingMessage,
            recipientCount: selectedAssets.length || chatMemberSelectOptions.length,
        };

        setMessages([...messages, message]);
    };

    const handleDiscardMessage = () => {
        gaPush({
            category: TRACKING_CATEGORIES.BROADCAST,
            action: 'Discard message',
            label: 'Clicked on discard button to abort message',
        });
        setShowConfirmation(false);
    };

    const totalRecipients = selectedAssets.length || chatMemberSelectOptions.length;

    return (
        <div className='display-flex flex-column height-100vh'>
            <div className='flex-1-1 overflow-y-auto no-scrollbars'>
                <AppNavigationBar
                    title={<FormattedMessage id='intl-msg:chat.broadcast.title' />}
                    className='border border-bottom-only position-fixed top-0 bg-white z-index-max height-50'
                    onNavigateBack={handleNavigateBack}
                />
                <CustomState
                    headline={<FormattedMessage id='intl-msg:chat.broadcast.title' />}
                    message={<FormattedMessage id='intl-msg:chat.broadcast.message' />}
                    outerClassName='border-none margin-top-50'
                    condensed
                    icons={[
                        {
                            name: 'rioglyph-megaphone',
                            className: 'text-size-300pct',
                        },
                    ]}
                />
                <BroadcastRecipientsSelect
                    options={chatMemberSelectOptions}
                    onSelectRecipients={handleSelectRecipients}
                    className='form-group margin-x-auto padding-x-15 max-width-300'
                />
                <BroadcastMessages
                    messages={messages}
                    className='display-flex flex-column gap-10 padding-15 padding-bottom-0 margin-top-50 border border-top-only '
                />
                <div ref={scrollToRef} />
            </div>
            <MessageInput onSendClicked={handleSendAttempt} sendEnabled hideDisclaimer />
            <BroadcastConfirmation
                show={showConfirmation}
                totalRecipients={totalRecipients}
                onConfirm={handleConfirmSend}
                onCancel={handleDiscardMessage}
            />
        </div>
    );
};
