import { ChatIcon } from '../../components/ChatIcon';
import { ListItem } from '../../components/ListItem';
import { DriverNameFormat } from '../../components/DriverNameFormat';
import type { Chat } from '../../services/chatAdministrationApi';

type RecipientListItemProps = {
    recipient: Chat;
    onClick: () => void;
};

export const RecipientListItem = (props: RecipientListItemProps) => {
    const { recipient, onClick } = props;

    return (
        <ListItem className='RecipientListItem' onClick={onClick}>
            <>
                <ChatIcon type={recipient.type} />
                <div className='flex-1-1 text-medium'>
                    {recipient.lastName ? (
                        <DriverNameFormat firstName={recipient.firstName} lastName={recipient.lastName} />
                    ) : (
                        recipient.name
                    )}
                </div>
            </>
        </ListItem>
    );
};
