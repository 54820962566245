import { createContext, useContext, type RefObject } from 'react';

export type AppContextType = {
    sidebarRef: RefObject<HTMLDivElement> | null;
    navigateToDefault: (source?: string) => void;
    navigateToNewChat: (source?: string) => void;
    navigateToBroadcast: (source?: string) => void;
    navigateToChat: (channel: string, source?: string) => void;
    navigateToFeedback: (source?: string) => void;
    navigateToHelp: (source?: string) => void;
};

export const AppContext = createContext<AppContextType>({
    sidebarRef: null,
    navigateToDefault: () => {},
    navigateToNewChat: () => {},
    navigateToBroadcast: () => {},
    navigateToChat: () => {},
    navigateToFeedback: () => {},
    navigateToHelp: () => {},
});

export const useAppContext = () => useContext(AppContext);
